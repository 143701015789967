import { FormInputField } from '@cooltra/form';
import { InputLabel } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import messages from '../messages';

export const MunicipalityCodeField = () => {
  return (
    <FormInputField
      name="municipalityCode"
      id="municipality-code"
      type="number"
      label={
        <InputLabel htmlFor="municipality-code">
          <FormattedMessage {...messages.municipalityCode} />
        </InputLabel>
      }
    />
  );
};
