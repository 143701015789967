import { defineMessages } from 'react-intl';
import { AccessoryName } from '@cooltra/utils';

export default defineMessages<AccessoryName>({
  FAST_FERRY_TO_FORMENTERA_BALEARIA: {
    defaultMessage: 'Ferry to Formentera: Discover (30 min)',
  },
  FAST_FERRY_TO_FORMENTERA_DISCOVER: {
    defaultMessage: 'Fast Ferry to Formentera (DISCOVER)',
  },
  SECOND_HELMET: {
    defaultMessage: 'Second helmet',
  },
  GLOVES: {
    defaultMessage: 'Gloves',
  },
  STICKER_REMOVAL: {
    defaultMessage: 'Sticker removal',
  },
  ASISTENCIA_EXTENDIDA: {
    defaultMessage: 'Extended assistance',
  },
  CONDUCTOR_ADICIONAL: {
    defaultMessage: 'Additional driver',
  },
  COMFORT_PACK: {
    defaultMessage: 'Pack Comfort',
  },
  FERRY_TO_FORMENTERA_SEA_EXPERIENCE: {
    defaultMessage: 'Economic Ferry to Formentera (SEA EXPERIENCE)\n',
  },
  BUNDLE_FERRY_TO_FORMENTERA: {
    defaultMessage: 'Scooter & Economic Ferry Formentera (Sea Experience)',
  },
  SCOOTER_PICK_UP: {
    defaultMessage: 'Return',
  },
  TICKETS_JULIA_TRAVEL: {
    defaultMessage: 'Julia Travel Tickets',
  },
  SCOOTER_DELIVERY: {
    defaultMessage: 'Delivery',
  },
  DELIVERIES: {
    defaultMessage: 'Deliveries',
  },
  LOCK: {
    defaultMessage: 'Lock',
    id: 'accessoriesLock',
  },
  SILLA_PARA_NIÑOS: {
    defaultMessage: 'Child seat',
  },
  CANCELACION_LAST_MINUTE: {
    defaultMessage: 'Last minute cancellation',
  },
  TOP_CASE: {
    defaultMessage: 'Top case',
  },
  TOP_CASE_PLUS: {
    defaultMessage: 'Top case plus (40L)',
  },
  TOP_CASE_26_29_L: {
    defaultMessage: 'Top case (26-29L)',
  },
  TOP_CASE_37_42_L: {
    defaultMessage: 'Top case (37-42L)',
  },
  OTHER_EXTRAS: {
    defaultMessage: 'Other extras',
  },
  OTHER_ACCESSORIES: {
    defaultMessage: 'Other accessories',
  },
  SOPORTE_TABLA_DE_SURF: {
    defaultMessage: 'Surfboard rack',
  },
  PHONE_HOLDER: {
    defaultMessage: 'Mobile phone holder',
  },
  WINDSHIELD: {
    defaultMessage: 'Windshield',
  },
  PACK_SECOND_HELMET_TOP_CASE_LOCK: {
    defaultMessage: 'Second Helmet + Trunk + Lock Pack',
  },
  SCOOTER_LEG_COVER_BLANKET: {
    defaultMessage: 'Scooter windshield blanket',
  },
  GPS: {
    defaultMessage: 'GPS',
  },
  SUBSTITUTION_VEHICLE: {
    defaultMessage: 'Substitution vehicle',
  },
  ADDITIONAL_BATTERY: {
    defaultMessage: 'Additional battery',
  },
  SCOOTER_DELIVERY_CITY_CENTER: {
    defaultMessage: 'Delivery in city center',
  },
  SCOOTER_PICK_UP_CITY_CENTER: {
    defaultMessage: 'Return in city center',
  },
  BATTERY: {
    defaultMessage: 'Battery',
  },
  BATTERY_SAFETY_BAG: {
    defaultMessage: 'Battery safety bag',
  },
  BRAKE_ALARM_LOCK: {
    defaultMessage: 'Brake alarm lock',
  },
  CHAIN_LOCK: {
    defaultMessage: 'Chain lock',
  },
  CHARGER: {
    defaultMessage: 'Charger',
  },
  EXTRA_CHARGER: {
    defaultMessage: 'Extra charger',
  },
  RING_LOCK_AND_KEY_NUMBER: {
    defaultMessage: 'Ring lock and key number',
  },
});
