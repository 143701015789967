import { FormCheckboxField } from '@cooltra/form';
import { InputLabel } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

export const IsVisibleCheck = () => {
  return (
    <FormCheckboxField
      id="allows-online-sales"
      name="allowsOnlineSales"
      label={
        <InputLabel emphasis="low" htmlFor="allows-online-sales">
          <FormattedMessage {...messages.allowsOnlineSales} />
        </InputLabel>
      }
    />
  );
};
