import { FormSelectFieldProps, FormSelectField } from '@cooltra/form';
import { InputLabel } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { DelegationOptions } from '@cooltra/station-based-api';

import messages from './messages';

export type DelegationSelectFieldProps = Omit<
  FormSelectFieldProps,
  'name' | 'id' | 'label' | 'isClearable' | 'options'
>;

export const DelegationSelectField = (props: DelegationSelectFieldProps) => {
  return (
    <FormSelectField
      id="delegation-field"
      name="delegation"
      options={DelegationOptions}
      isClearable
      label={
        <InputLabel htmlFor="delegation-field">
          <FormattedMessage {...messages.delegation} />
        </InputLabel>
      }
      {...props}
    />
  );
};
