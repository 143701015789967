import { SaleType, ServicePointVehicleTypes } from '@cooltra/station-based-api';
import { FormattedMessage } from 'react-intl';

import messages from './messages';
import { VehicleType } from './VehicleType';
import { AddVehicleTypeModal } from './AddVehicleTypeModal/AddVehicleTypeModal';

export type VehicleTypesSectionProps = {
  vehicleTypes: ServicePointVehicleTypes[];
  saleType: SaleType;
};

export const VehicleTypesSection = ({
  vehicleTypes,
  saleType,
}: VehicleTypesSectionProps) => {
  return (
    <div className="pt-16">
      <div className="w-full flex justify-between items-center pb-14">
        <h3>
          <FormattedMessage
            {...messages.title}
            values={{
              sale_type:
                saleType === 'SHORT_TERM' ? 'short term' : 'subscription',
            }}
          />
        </h3>
        <AddVehicleTypeModal sale_type={saleType} />
      </div>
      <div className="flex flex-col gap-5">
        {vehicleTypes.length === 0 ? (
          <span className="mx-auto pt-16 text-neutral-600 text-sm">
            <FormattedMessage {...messages.noVehicles} />
          </span>
        ) : (
          vehicleTypes
            .filter((vehicleType) => vehicleType.saleType == saleType)
            .map((vehicleType, idx) => (
              <VehicleType key={`${vehicleType.id}-${idx}`} {...vehicleType} />
            ))
        )}
      </div>
    </div>
  );
};
