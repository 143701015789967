import { SubscriptionPlan } from '@cooltra/station-based-api';
import { defineMessages } from 'react-intl';

export default defineMessages({
  subscription: {
    defaultMessage: 'Subscription',
  },
  totalDuration: {
    defaultMessage: 'Total duration',
  },
  nextReview: {
    defaultMessage: 'Next renewal',
  },
  months: {
    defaultMessage: '{total, plural, one {# month} other {# months}}',
  },
  monthlyCharge: {
    defaultMessage: 'Monthly charge',
  },
  savedCreditCard: {
    defaultMessage: 'Saved credit card',
  },
  unpaidTickets: {
    defaultMessage: '{unpaidTickets} Unpaid',
  },
  subscriptionCancellation: {
    defaultMessage: 'Subscription cancellation',
  },
});

export const planLabels = defineMessages<SubscriptionPlan>({
  URBAN: {
    defaultMessage: 'Urban - 500km/month',
  },
  INTERMEDIATE: {
    defaultMessage: 'Intermediate - 1000km/month',
  },
  EXTRA_URBAN: {
    defaultMessage: 'Extra Urban - 1500km/month',
  },
});
