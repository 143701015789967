import { useGeofencesQuery, Rental } from '@cooltra/api';
import { System } from '@cooltra/utils';
import { NavigationControl } from 'react-map-gl';

import { BaseMap, DropOffMarker, Geofences } from '~/common';
import { isTestingEnv } from '~/utils/e2e';

export type RentalDropOffMapProps = Pick<Rental, 'dropOff'> & {
  system: System;
  isBikeRental: boolean;
};

export const RentalDropOffMap = ({
  dropOff,
  isBikeRental,
}: RentalDropOffMapProps) => {
  const vehicleType = isBikeRental ? 'BIKE' : 'SCOOTER';
  const { data: geofences } = useGeofencesQuery({
    vehicleType,
  });

  if (isTestingEnv() || !dropOff) {
    return <div className="h-full w-full bg-neutral-100" />;
  }

  return (
    <BaseMap
      id="rental-drop-off-map"
      initialViewState={{
        zoom: 16,
        latitude: dropOff.latitude,
        longitude: dropOff.longitude,
      }}
      style={{ width: '100%', height: '100%' }}
    >
      <NavigationControl showZoom showCompass={false} />
      <Geofences geofences={geofences} vehicleType={vehicleType} />
      {dropOff && (
        <DropOffMarker
          latitude={dropOff.latitude}
          longitude={dropOff.longitude}
        />
      )}
    </BaseMap>
  );
};
