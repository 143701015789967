import {
  Contract,
  ContractPayment,
  TicketPaymentMethod,
  useContractPaymentsQuery,
  usePayTicketContractMutation,
  usePayTicketContractWithQRMutation,
  useMarkSubscriptionTicketAsPaidMutation,
} from '@cooltra/station-based-api';
import { Button, CheckboxField, InputLabel, Modal } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { useToggle } from '@cooltra/hooks';
import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import {
  Form,
  FormButton,
  FormContext,
  FormRadioGroupCustomField,
  useForm,
} from '@cooltra/form';

import {
  Amount,
  FormBillingServicePointSelect,
  PaymentMethodOption,
  QRPayment,
} from '~/common';
import { useNotification, usePolling } from '~/hooks';

import messages from './messages';

type SubscriptionTicketFormValues = {
  paymentMethod: TicketPaymentMethod;
  billingServicePointId: string;
};

export type PaySubscriptionTicketProps = {
  payment?: ContractPayment;
  contract: Contract;
  disabled: boolean;
};

export const PaySubscriptionTicket = ({
  payment,
  disabled,
  contract: { contractId, hasLinkedPaymentMethod, pendingAmount, details },
}: PaySubscriptionTicketProps) => {
  const queryClient = useQueryClient();
  const [isConfirmed, { toggle: toggleOnConfirmCash }] = useToggle();
  const [paymentId, setPaymentId] = useState('');
  const [isQRShown, { toggleOn: showQR, toggleOff: hideQR }] = useToggle();

  const [isModalOpen, { toggleOff, toggleOn }] = useToggle();

  const markSubscriptionTicketAsPaid = useMarkSubscriptionTicketAsPaidMutation(
    contractId,
    payment?.id || ''
  );

  const openModal = () => {
    if (payment?.totalPrice.value === 0) {
      markSubscriptionTicketAsPaid
        .mutateAsync()
        .catch(() => addErrorNotification());
    } else {
      toggleOn();
      setPaymentId(payment?.id || '');
    }
  };

  const closeModal = () => {
    toggleOff();
    setPaymentId('');
  };

  const { addErrorNotification } = useNotification();

  const payTicketMutation = usePayTicketContractMutation(contractId, paymentId);

  const payTicketWithQRMutation = usePayTicketContractWithQRMutation(
    contractId,
    paymentId
  );

  const handleOnSubmit = async ({
    paymentMethod,
    billingServicePointId,
  }: SubscriptionTicketFormValues) => {
    if (!paymentId) {
      return;
    }

    if (paymentMethod === 'CARD' || paymentMethod === 'CASH') {
      return payTicketMutation
        .mutateAsync({
          paymentMethod: paymentMethod,
          billingServicePointId,
          operationNumber: null,
          lastFourDigits: null,
        })
        .catch(() => addErrorNotification());
    }

    if (paymentMethod === 'STRIPE_QR') {
      return payTicketWithQRMutation
        .mutateAsync({
          billingServicePointId,
        })
        .then(() => showQR())
        .catch(() => addErrorNotification());
    }
  };

  const { data: payments, refetch: refetchPayments } =
    useContractPaymentsQuery(contractId);

  const form = useForm<SubscriptionTicketFormValues>({
    initialValues: {
      paymentMethod: hasLinkedPaymentMethod ? 'CARD' : 'STRIPE_QR',
      billingServicePointId: details!.servicePointId,
    },
    onSubmit: handleOnSubmit,
  });

  const { isPolling } = usePolling({
    fn: refetchPayments,
    onSettle: () => {
      payTicketMutation.reset();
      queryClient.invalidateQueries({ queryKey: ['contract', contractId] });
      if (payTicketMutation.error) {
        addErrorNotification();
      } else {
        toggleOff();
      }
    },
    maxAttempts: 5,
    interval:
      (form.values.paymentMethod === 'CARD' ||
        form.values.paymentMethod === 'CASH') &&
      payTicketMutation.isSuccess &&
      payments?.find(({ id }) => id === paymentId)?.status !== 'PAID'
        ? 1000
        : 0,
  });

  const loading =
    isPolling ||
    payTicketMutation.isPending ||
    payTicketWithQRMutation.isPending;

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        onAfterClose={hideQR}
      >
        <FormContext.Provider value={form}>
          {isQRShown ? (
            <QRPayment className="px-20 pb-12" id={paymentId} />
          ) : (
            <Form>
              <div className="min-w-xl px-24 pb-12 flex flex-col gap-2 items-center">
                <FormRadioGroupCustomField
                  className="w-full"
                  id="payment-method"
                  name="paymentMethod"
                  legend={
                    <legend className="block text-xl font-semibold text-neutral-700 mb-8 text-center">
                      <FormattedMessage {...messages.legend} />
                    </legend>
                  }
                  items={(hasLinkedPaymentMethod
                    ? ['CARD', 'STRIPE_QR', 'CASH']
                    : ['STRIPE_QR', 'CASH']
                  ).map((method) => ({
                    label: '',
                    value: method,
                  }))}
                  renderRadio={({ checked, value }) => (
                    <PaymentMethodOption
                      type={value as TicketPaymentMethod}
                      checked={checked}
                    />
                  )}
                />
                {form.values.paymentMethod === 'CASH' && (
                  <CheckboxField
                    id="confirm-cash-payment"
                    checked={isConfirmed}
                    label={
                      <InputLabel emphasis="low" htmlFor="confirm-cash-payment">
                        <FormattedMessage {...messages.confirmPayment} />
                      </InputLabel>
                    }
                    onChange={toggleOnConfirmCash}
                    className="mt-9 self-start"
                  />
                )}
                <div className="pt-4 w-full">
                  <FormBillingServicePointSelect />
                </div>
                <FormButton
                  className="mt-8"
                  loading={loading}
                  disablePristine={false}
                  disabled={
                    form.values.paymentMethod === 'CASH' && !isConfirmed
                  }
                >
                  <FormattedMessage
                    {...messages.sendPayment}
                    values={{
                      amount: <Amount {...pendingAmount} />,
                    }}
                  />
                </FormButton>
              </div>
            </Form>
          )}
        </FormContext.Provider>
      </Modal>
      <Button
        emphasis="high"
        onClick={openModal}
        disabled={loading || disabled}
        className="self-end"
      >
        <FormattedMessage {...messages.payTicket} />
      </Button>
    </>
  );
};
