import { Card, Placeholder, MultiSelect } from '@cooltra/ui';
import { useLocationsQuery } from '@cooltra/api';
import { System, consecutiveNumbers, stringifyParams } from '@cooltra/utils';
import { RouterLink } from '@cooltra/navigation';
import { getErrorStatus } from '@cooltra/axios';
import { FormattedMessage, useIntl } from 'react-intl';
import { useState } from 'react';

import { ErrorPage, Four0Three, useSystemOptions } from '~/common';

import messages from './messages';

export const VehiclesLocationsList = () => {
  const { formatMessage } = useIntl();

  const [systems, setSystems] = useState<string[]>([]);
  const options = useSystemOptions();

  const { isError, error, data } = useLocationsQuery({
    system: systems as System[],
  });

  if (isError && getErrorStatus(error) === 403) {
    return <Four0Three />;
  }

  if (isError) {
    return <ErrorPage />;
  }

  return (
    <div
      className="container min-w-screen-sm max-w-2xl py-12"
      data-testid="VEHICLE_LOCATIONS"
    >
      <div className="mb-10 w-full">
        <h2 className="text-neutral-1000 mb-11">
          <FormattedMessage {...messages.title} />
        </h2>
        {options.length > 1 && (
          <MultiSelect
            aria-label={formatMessage(messages.allSystems)}
            isClearable
            className="w-56"
            onChange={(options) =>
              setSystems(options.map(({ value }) => value))
            }
            options={options}
            placeholder={formatMessage(messages.allSystems)}
          />
        )}
      </div>
      <ul className="flex flex-col gap-2.5">
        {!data &&
          consecutiveNumbers(8).map((i) => (
            <li key={`location-list-placeholder-${i}`}>
              <Card className="h-16 flex items-center px-6">
                <Placeholder className="h-2 w-60" />
              </Card>
            </li>
          ))}
        {data &&
          data
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(({ locationId, name, vehiclesCount }) => (
              <li key={locationId}>
                <Card className="h-16 flex px-6 gap-14">
                  <div className="flex w-full items-center">
                    <span className="flex-1 text-sm font-semibold">{name}</span>
                    <RouterLink
                      variant="muted"
                      className="px-1 text-sm"
                      to={`/vehicles?${stringifyParams({
                        location: locationId,
                        system: systems,
                      })}`}
                    >
                      <FormattedMessage
                        values={{ count: vehiclesCount }}
                        {...messages.count}
                      />
                    </RouterLink>
                  </div>
                </Card>
              </li>
            ))}
      </ul>
    </div>
  );
};
