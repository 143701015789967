import { FormInputField } from '@cooltra/form';
import { InputLabel } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

export const PrerollHours = () => {
  return (
    <div className="grid grid-cols-2 gap-2">
      <FormInputField
        name="shopPrerollHours"
        id="shop-preroll-hours"
        type="number"
        label={
          <InputLabel htmlFor="shop-preroll-hours">
            <FormattedMessage {...messages.prerollShopHours} />
          </InputLabel>
        }
      />
      <FormInputField
        name="deliveryPointPrerollHours"
        id="delivery-point-preroll-hours"
        type="number"
        label={
          <InputLabel htmlFor="delivery-point-preroll-hours">
            <FormattedMessage {...messages.prerollDPHours} />
          </InputLabel>
        }
      />
    </div>
  );
};
