import { systemGeolocations } from '@cooltra/utils';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { PropsWithChildren, useState } from 'react';

import { useVehiclesFilters } from '../vehicles-filters';

import { MapCenterContext, BaseViewState } from './MapCenterContext';

export const MapCenterProvider = ({ children }: PropsWithChildren) => {
  const { data: claims } = useAuthClaimsQuery();
  const { values } = useVehiclesFilters();

  const getInitialCenter = () => {
    if (!claims) {
      return {
        zoom: 4.5,
        ...systemGeolocations.Barcelona,
      };
    }
    if (claims.systems.length === 1) {
      return {
        zoom: 12,
        ...systemGeolocations[claims.systems[0]],
      };
    }
    if (values.system.length === 1) {
      return {
        zoom: 12,
        ...systemGeolocations[values.system[0]],
      };
    }
    return {
      zoom: 4.5,
      ...systemGeolocations.Barcelona,
    };
  };

  const [viewState, updateViewState] =
    useState<BaseViewState>(getInitialCenter());

  return (
    <MapCenterContext.Provider value={{ viewState, updateViewState }}>
      {children}
    </MapCenterContext.Provider>
  );
};
