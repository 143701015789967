import { defineMessages } from 'react-intl';

export default defineMessages({
  addVehicle: {
    defaultMessage: 'Add vehicle type',
  },
  title: {
    defaultMessage: 'New vehicle type',
  },
  vehicleTypeId: {
    defaultMessage: 'Vehicle type',
  },
  vehicleTypeIdPlaceholder: {
    defaultMessage: 'Select a vehicle type',
  },
  paidAccessories: {
    defaultMessage: 'Paid extras',
  },
  noPaidAccessories: {
    defaultMessage: 'No paid accessories',
  },
  freeAccessories: {
    defaultMessage: 'Free extras',
  },
  noFreeAccessories: {
    defaultMessage: 'No free accessories',
  },
  submit: {
    defaultMessage: 'Save',
  },
  orSimilar: {
    defaultMessage: 'or similar',
  },
  success: {
    defaultMessage: 'Vehicle type saved successfully',
  },
});
