import { useBonusZonesQuery } from '@cooltra/api';
import { System } from '@cooltra/utils';

import { BonusZoneWithMarker } from './BonusZoneWithMarker';
import { BonusZone, BonusZoneStyle } from './BonusZone';

export type BonusZoneByHomeSystemProps = {
  system: System;
  styles?: BonusZoneStyle;
  withMarkers?: boolean;
};

export const BonusZoneByHomeSystem = ({
  system,
  withMarkers,
  styles,
}: BonusZoneByHomeSystemProps) => {
  const { data } = useBonusZonesQuery(system);

  if (!data) {
    return <></>;
  }

  if (withMarkers) {
    return (
      <>
        {data.bonusZones
          .filter((bonusZone) => bonusZone.enabled)
          .map((bonusZone) => (
            <BonusZoneWithMarker
              key={bonusZone.marker.latitude}
              bonusZone={bonusZone}
              system={system}
              styles={styles}
            />
          ))}
      </>
    );
  }

  return (
    <>
      {data.bonusZones
        .filter((bonusZone) => bonusZone.enabled)
        .map((bonusZone) => (
          <BonusZone
            key={bonusZone.marker.latitude}
            bonusZone={bonusZone}
            system={system}
            styles={styles}
          />
        ))}
    </>
  );
};
