import { ReactNode } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import {
  useContractPaymentsQuery,
  useContractQuery,
  useServicePointsQuery,
} from '@cooltra/station-based-api';
import { SpinnerScreen } from '@cooltra/ui';
import { Helmet } from 'react-helmet';

import { ErrorPage, Four0Four, QRCode } from '~/common';
import { oneDay } from '~/utils/time';

import { SubscriptionContractDetails } from '../SubscriptionContractDetails';
import { SubscriptionContractOverview } from '../SubscriptionContractOverview/SubscriptionContractOverview';
import { SubscriptionContractDriver } from '../SubscriptionContractDriver/SubscriptionContractDriver';
import { SubscriptionContractVehicles } from '../SubscriptionContractVehicles';
import { SubscriptionContractExtras } from '../SubscriptionContractExtras';
import { SubscriptionContractChecksAndDamages } from '../SubscriptionContractChecksAndDamages/SubscriptionChecksAndDamages';
import { SubscriptionContractPayments } from '../SubscriptionContractPayments';

import { RedirectOnDisabled } from './RedirectOnDisabled';
import { SubscriptionContractNavbar } from './SubscriptionContractNavbar';
import { SubscriptionContractSidebar } from './SubscriptionContractSidebar';

export const SubscriptionContract = () => {
  const { contractId = '' } = useParams();

  const { data: servicePoints, ...servicePointsQuery } = useServicePointsQuery(
    {},
    {
      staleTime: oneDay,
    }
  );

  const { data: contract, ...contractQuery } = useContractQuery(contractId, {
    enabled: !!contractId,
  });

  const { data: payments = [], ...paymentsQuery } = useContractPaymentsQuery(
    contractId,
    { enabled: !!contract && !contract.isMigrated }
  );

  if (
    contractQuery.isError ||
    paymentsQuery.isError ||
    servicePointsQuery.isError
  ) {
    return <ErrorPage />;
  }

  if (!contract || !servicePoints) {
    return <SpinnerScreen />;
  }

  const getRedirectToOverviewOnDisabled = (children: ReactNode) => (
    <RedirectOnDisabled contract={contract} redirectPath="../overview">
      {children}
    </RedirectOnDisabled>
  );

  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <div className="h-screen-content flex w-full">
        <SubscriptionContractSidebar contract={contract} />
        <div className="flex flex-col h-screen-content w-full">
          <SubscriptionContractNavbar contract={contract} />
          <div className="w-full gap-8 flex-1 px-4 lg:px-8 pt-4 lg:pt-8 overflow-y-scroll">
            <div className="w-full">
              <Routes>
                <Route
                  path="/overview"
                  element={
                    <RedirectOnDisabled
                      contract={contract}
                      redirectPath="../details"
                    >
                      <SubscriptionContractOverview
                        contract={contract}
                        payments={payments}
                      />
                    </RedirectOnDisabled>
                  }
                />
                <Route
                  path="/details"
                  element={getRedirectToOverviewOnDisabled(
                    <SubscriptionContractDetails contract={contract} />
                  )}
                />
                <Route
                  path="/driver"
                  element={getRedirectToOverviewOnDisabled(
                    <SubscriptionContractDriver contract={contract} />
                  )}
                />
                <Route
                  path="/vehicles"
                  element={getRedirectToOverviewOnDisabled(
                    <>
                      <SubscriptionContractVehicles contract={contract} />
                      <QRCode />
                    </>
                  )}
                />
                <Route
                  path="/extras"
                  element={getRedirectToOverviewOnDisabled(
                    <>
                      <SubscriptionContractExtras
                        contract={contract}
                        payments={payments}
                      />
                      <QRCode />
                    </>
                  )}
                />
                <Route
                  path="/payments"
                  element={getRedirectToOverviewOnDisabled(
                    <>
                      <SubscriptionContractPayments contract={contract} />
                      <QRCode />
                    </>
                  )}
                />
                <Route
                  path="/checks-damages"
                  element={getRedirectToOverviewOnDisabled(
                    <SubscriptionContractChecksAndDamages contract={contract} />
                  )}
                />
                <Route path="*" element={<Four0Four />} />
              </Routes>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
