import { ToggleField } from '@cooltra/ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { ChangeEventHandler, useMemo, useState } from 'react';
import {
  useServicePointQuery,
  useToggleActivateServicePointMutation,
} from '@cooltra/station-based-api';

import { useNotification } from '~/hooks';

import messages from './messages';

export type IsActiveToggleProps = {
  servicePointId: string;
};

export const IsActiveToggle = ({ servicePointId }: IsActiveToggleProps) => {
  const { data: servicePointData } = useServicePointQuery(servicePointId);
  const { addErrorNotification, addSuccessNotification } = useNotification();
  const { formatMessage } = useIntl();
  const [isActive, setIsActive] = useState(servicePointData?.isActive);

  const { mutateAsync } = useToggleActivateServicePointMutation(servicePointId);

  const handleStatusToggle = ({
    servicePointStatus,
  }: {
    servicePointStatus: boolean;
  }) => {
    return mutateAsync({ isActive: servicePointStatus })
      .then(() => addSuccessNotification(formatMessage(messages.statusChange)))
      .catch(() => {
        setIsActive(!servicePointStatus);
        addErrorNotification();
      });
  };

  const handleOnChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { checked } = event.target;

    if (checked) {
      handleStatusToggle({ servicePointStatus: true });
    } else {
      handleStatusToggle({ servicePointStatus: false });
    }

    setIsActive(checked);
  };

  const isDisabled = useMemo(() => {
    if (!servicePointData) return true;

    return (
      !servicePointData.delegation ||
      !servicePointData.destination ||
      !servicePointData.destinationAccount ||
      !servicePointData.minDaysForDeliveryPoint ||
      !servicePointData.shopPrerollHours ||
      !servicePointData.deliveryPointPrerollHours ||
      (!servicePointData.allowsOnlineSales &&
        !servicePointData.vehicleTypes.length) ||
      !servicePointData.pricelistPOS ||
      (servicePointData.allowsOnlineSales && !servicePointData.pricelistWeb)
    );
  }, [servicePointData]);

  return (
    <ToggleField
      data-testid="IS_ACTIVE_TOGGLE"
      id="is-active"
      checked={isActive}
      onChange={handleOnChange}
      disabled={isDisabled}
      label={
        <label htmlFor="is-active">
          <FormattedMessage {...messages.active} />
        </label>
      }
    />
  );
};
