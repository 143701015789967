import { useFormContext } from '@cooltra/form';
import { stringifyParams } from '@cooltra/utils';
import { RouterButton } from '@cooltra/navigation';
import { FormattedMessage } from 'react-intl';

import { formatDateQueryParam } from '../../utils';
import { useFilters } from '../../useFilters';

import { FilterFormValues } from './FilterFormProvider';
import messages from './messages';

type ShowContractLinkProps = {
  onClick: () => void;
};

export const ShowContractLink = ({ onClick }: ShowContractLinkProps) => {
  const { date } = useFilters();
  const {
    values: { serviceType, servicePointId, status, saleType },
  } = useFormContext<FilterFormValues>();

  return (
    <RouterButton
      to={`?${stringifyParams({
        date: formatDateQueryParam(date),
        serviceType: serviceType ? serviceType : '',
        servicePointId: servicePointId ? servicePointId : '',
        status: status,
        saleType: saleType ? saleType : '',
      })}`}
      onClick={onClick}
      emphasis="high"
    >
      <FormattedMessage {...messages.showContracts} />
    </RouterButton>
  );
};
