import { useToggle } from '@cooltra/hooks';
import { Button, Modal } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import messages from './messages';
import { AddVehicleForm } from './AddVehicleForm';

export type AddVehicleTypeModalProps = {
  sale_type: string;
};

export const AddVehicleTypeModal = ({
  sale_type,
}: AddVehicleTypeModalProps) => {
  const [isOpen, { toggleOn, toggleOff }] = useToggle(false);
  const handleClick = () => toggleOn();

  return (
    <>
      <Modal isOpen={isOpen} onRequestClose={toggleOff}>
        <h2 className="text-center">
          <FormattedMessage {...messages.title} />
        </h2>
        <AddVehicleForm handleClose={toggleOff} sale_type={sale_type} />
      </Modal>
      <Button emphasis="medium" onClick={handleClick}>
        <FormattedMessage {...messages.addVehicle} />
      </Button>
    </>
  );
};
