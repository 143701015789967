import { useBonusZonesQuery } from '@cooltra/api';
import { System } from '@cooltra/utils';

import { BonusZoneWithMarker } from './BonusZoneWithMarker';

export const BonusZoneById = ({
  system,
  bonusZoneId,
}: {
  system: System;
  bonusZoneId: string;
}) => {
  const { data } = useBonusZonesQuery(system);

  if (!data) {
    return <></>;
  }

  return (
    <>
      {data.bonusZones
        .filter((bonusZone) => bonusZone.bonusZoneId.toString() === bonusZoneId)
        .map((bonusZone) => (
          <BonusZoneWithMarker
            key={bonusZone.marker.latitude}
            bonusZone={bonusZone}
            system={system}
          />
        ))}
    </>
  );
};
