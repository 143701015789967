import { ThreeStateValue, IncidentSort } from '@cooltra/api';
import { System } from '@cooltra/utils';

export type IncidentsFiltersFormValues = {
  system: System[];
  operatorGroupId: string;
  operatorId: string;
  open: ThreeStateValue;
  sort: IncidentSort;
};

export const incidentsFiltersInitialValues: IncidentsFiltersFormValues = {
  system: [],
  operatorGroupId: '',
  operatorId: '',
  open: 'true',
  sort: 'comments.newest_comment_first',
};
