import { defineMessages } from 'react-intl';

export default defineMessages({
  dropOffAppointment: {
    defaultMessage: 'Drop off appointment',
  },
  date: {
    defaultMessage: 'Date',
  },
  time: {
    defaultMessage: 'Time',
  },
});
