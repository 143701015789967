import {
  AccessoriesListItem,
  ServicePointVehicleTypes,
  useAccessoriesQuery,
  useRemoveVehicleTypeFromServicePointMutation,
  useVehicleTypesQuery,
  VehicleTypeListItem,
} from '@cooltra/station-based-api';
import { Badge, Button, Card, Spinner } from '@cooltra/ui';
import { AccessoryName, getVehicleName, VehicleTypeName } from '@cooltra/utils';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { useAccessoryName, useNotification } from '~/hooks';

import messages from './messages';

export type VehicleTypeProps = ServicePointVehicleTypes;

const getVehicleTypeName = (
  allVehicles: VehicleTypeListItem[],
  vehicleId: string
) => {
  const vehicleTypeName = allVehicles.find(
    (vehicleType) => vehicleType.id === vehicleId
  )?.name;

  return getVehicleName(vehicleTypeName as VehicleTypeName) || vehicleId;
};

const getAccessoriesWithFree = (
  allAccessories: AccessoriesListItem[],
  accessories: ServicePointVehicleTypes['accessories']
) =>
  accessories.map(({ accessoryId, free }) => {
    const accessory = allAccessories.find(
      (accessory) => accessory.id === accessoryId
    );
    return {
      accessoryId,
      name: accessory?.name || accessoryId,
      free,
    };
  });

export const VehicleType = ({
  id,
  accessories,
  saleType,
}: VehicleTypeProps) => {
  const { servicePointId = '' } = useParams<'servicePointId'>();
  const getAccessoryName = useAccessoryName();
  const { formatMessage } = useIntl();
  const { addErrorNotification, addSuccessNotification } = useNotification();

  const { data: accessoriesData, isLoading: accessoriesAreLoading } =
    useAccessoriesQuery({ sale_type: saleType });
  const { data: allVehicleTypes, isLoading: vehiclesAreLoading } =
    useVehicleTypesQuery({ sale_type: saleType });

  const { mutateAsync } = useRemoveVehicleTypeFromServicePointMutation(
    servicePointId,
    id
  );

  if (
    !allVehicleTypes ||
    !accessoriesData ||
    vehiclesAreLoading ||
    accessoriesAreLoading
  ) {
    return (
      <Card className="w-full h-8">
        <Spinner />
      </Card>
    );
  }
  const accessoriesWithFree = getAccessoriesWithFree(
    accessoriesData,
    accessories
  );

  const vehicleTypeName = getVehicleTypeName(allVehicleTypes, id);

  const removeVehicle = () => {
    mutateAsync()
      .then(() =>
        addSuccessNotification(
          formatMessage(messages.removeVehicleNotification)
        )
      )
      .catch(() => addErrorNotification());
  };

  return (
    <Card className="p-8 flex justify-between items-center">
      <div className="flex flex-col w-2/3 gap-3">
        <h3 className="text-sm">
          {vehicleTypeName}
          <span className="pl-1">
            <FormattedMessage {...messages.orSimilar} />
          </span>
        </h3>
        <div className="flex-wrap flex w-full gap-3">
          {accessoriesWithFree.map(({ name, free, accessoryId }, idx) => (
            <Badge key={`${id}-${accessoryId}=${idx}`}>
              <span className="text-sm">
                {getAccessoryName(name as AccessoryName)}
                <span className="text-neutral-400 pl-1">
                  {free && <FormattedMessage {...messages.free} />}
                </span>
              </span>
            </Badge>
          ))}
        </div>
      </div>
      <Button variant="danger" emphasis="low" onClick={removeVehicle}>
        <FormattedMessage {...messages.removeVehicle} />
      </Button>
    </Card>
  );
};
