import { FormattedMessage } from 'react-intl';
import { ContractPayment } from '@cooltra/station-based-api';
import {
  getInsuranceName,
  SubscriptionInsuranceName,
  getSubscriptionInsuranceName,
} from '@cooltra/utils';

import { Amount } from '../../Amount/Amount';

import messages from './messages';

export type CoverageProps = {
  className?: string;
  payment: ContractPayment;
  isSubscription: boolean;
};

export const Coverage = ({
  className,
  payment: { insurance },
  isSubscription,
}: CoverageProps) => {
  if (!insurance) {
    return null;
  }

  const { price, days, name } = insurance;
  const coverageName = isSubscription
    ? getSubscriptionInsuranceName(name.toString() as SubscriptionInsuranceName)
    : getInsuranceName(name);

  return (
    <div className={className}>
      <h3 className="text-sm text-neutral-800">
        <FormattedMessage {...messages.coverage} />
      </h3>
      <div className="flex justify-between my-2 gap-8 text-neutral-800">
        <span>
          {days ? (
            <FormattedMessage
              {...messages.days}
              values={{
                name: coverageName,
                contractDays: days,
              }}
            />
          ) : (
            coverageName
          )}
        </span>
        <Amount {...price} />
      </div>
    </div>
  );
};
