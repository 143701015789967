import { System, classNames } from '@cooltra/utils';
import { RouterBaseLink } from '@cooltra/navigation';
import { PropsWithChildren } from 'react';
import { useMatch } from 'react-router-dom';

import { SystemImage } from '~/common';

export type HomeSystemLinkProps = PropsWithChildren<{
  onClick: () => void;
  system: System;
}>;

export const HomeSystemLink = ({
  children,
  onClick,
  system,
}: HomeSystemLinkProps) => {
  const systemMatch = useMatch({
    path: `/zones/${system}`,
    end: false,
  });
  const isActive = !!systemMatch;

  const restrictionsMatch = useMatch({
    path: '/zones/:system/restrictions',
    end: true,
  });

  return (
    <RouterBaseLink
      to={`/zones/${system}/${
        restrictionsMatch ? 'restrictions' : 'bonus-zones'
      }`}
      data-testid={isActive && 'ACTIVE_HOME_SYSTEM'}
      onClick={onClick}
      className={classNames(
        'flex flex-col items-center justify-center py-3 rounded-lg hover:shadow-md focus:shadow-sm transition-all',
        isActive
          ? 'border-primary-500 border ring-inset ring-1 ring-primary-500'
          : 'border-neutral-100 border'
      )}
    >
      <SystemImage system={system} className="w-10 h-10 mb-1" />
      <span className="text-sm">{children}</span>
    </RouterBaseLink>
  );
};
