import { ThreeStateValue, ProblemReportsSort } from '@cooltra/api';
import { System } from '@cooltra/utils';

import { monthAgo } from '~/utils/date';

export type ProblemReportsFiltersFormValues = {
  sort: ProblemReportsSort;
  system: System[];
  open: ThreeStateValue;
  minDate: string;
  maxDate: string;
};

export const initialProblemReportsFiltersFormValues: ProblemReportsFiltersFormValues =
  {
    sort: 'createdAt.desc',
    system: [],
    open: 'true',
    minDate: monthAgo,
    maxDate: '',
  };
