import { useContext } from 'react';
import { useField } from '@cooltra/form';
import { capitalize } from '@cooltra/utils';

import { RouterBackLink } from '~/common';

import { PriceListTypeContext } from '../../PriceListTypeContext';

export const VehicleTypePriceListDetailsBackLink = () => {
  const { value: pricingGroup } = useField('pricingGroup');
  const { type } = useContext(PriceListTypeContext);

  return (
    <RouterBackLink
      to={`/station-based/price-lists/${type.toLowerCase()}/vehicle-types?pricingGroup=${pricingGroup}`}
    >
      {capitalize(pricingGroup.toLowerCase())}
    </RouterBackLink>
  );
};
