import { FormattedMessage, useIntl } from 'react-intl';
import { useAccessoriesQuery } from '@cooltra/station-based-api';
import { InputLabel, MultiSelectOption } from '@cooltra/ui';
import { FormMultiSelectField, useFormContext } from '@cooltra/form';
import { classNames } from '@cooltra/utils';

import {
  AccessoriesOptions,
  StationBasedFormValues,
  StationBasedStateType,
} from '~/common';
import { useAccessoryName } from '~/hooks';

import { AppliesToFormRadio } from '../AppliesToFormRadio/AppliesToFormRadio';

import messages from './messages';

type YesOrNoOptions = {
  label: string;
  value: AccessoriesOptions;
}[];

export const Accessories = () => {
  const getAccessoryName = useAccessoryName();
  const { formatMessage } = useIntl();

  const { values } = useFormContext<StationBasedFormValues>();
  const {
    editMode,
    stateType,
    promotionTarget,
    enableAccessories,
    enableGenericAppliesTo,
  } = values;
  const editingNoScheduledPromotion =
    editMode && stateType !== StationBasedStateType.SCHEDULED;
  const { data: accessories = [], isLoading } = useAccessoriesQuery({
    sale_type: 'SHORT_TERM',
  });

  const options: MultiSelectOption[] = accessories.map((accessory) => ({
    label: getAccessoryName(accessory.name),
    value: accessory.id,
  }));

  const yesOrNoOptions: YesOrNoOptions = [
    {
      label: formatMessage(messages.yes),
      value: 'ACCESSORIES_YES',
    },
    {
      label: formatMessage(messages.no),
      value: 'ACCESSORIES_NO',
    },
  ];

  const disabledForWebOffer =
    promotionTarget === 'WEB_OFFER' && enableAccessories === 'ACCESSORIES_NO';
  const disabledForCoupon =
    promotionTarget === 'COUPON' &&
    enableGenericAppliesTo != 'enableAccessories';

  return (
    <fieldset data-testid="ACCESSORIES_SELECTOR">
      <InputLabel htmlFor="accessories" className="mb-2">
        <FormattedMessage {...messages.accessory} />
      </InputLabel>
      <div className="flex items-center gap-4">
        <AppliesToFormRadio
          yesOrNoOptions={yesOrNoOptions}
          name="enableAccessories"
          disabled={editingNoScheduledPromotion}
        />
        <FormMultiSelectField
          id="accessories"
          name="accessories"
          options={options}
          isLoading={isLoading}
          disabled={
            disabledForCoupon ||
            disabledForWebOffer ||
            editingNoScheduledPromotion
          }
          placeholder={formatMessage(messages.allAccessories)}
          className={classNames(
            'w-full',
            disabledForCoupon || disabledForWebOffer ? 'invisible' : ''
          )}
        />
      </div>
    </fieldset>
  );
};
