import { FormattedMessage } from 'react-intl';
import {
  Contract,
  ContractAnnex as ApiContractAnnex,
  ContractDocument as ApiContractDocument,
} from '@cooltra/station-based-api';

import * as ContractAnnex from './ContractAnnex';
import * as ContractDocument from './ContractDocument';
import messages from './messages';

export type ContractOverviewHistoryProps = {
  contract: Contract;
};

export const ContractOverviewHistory = ({
  contract,
}: ContractOverviewHistoryProps) => {
  const { contractId, isMigrated, documents, user } = contract;

  if (!documents) return null;

  const contractDocuments = documents.filter(
    (document) =>
      document.status !== 'PENDING' && document.status !== 'CANCELED'
  );

  const annexes = contract.annexes || [];

  if (contractDocuments.length === 0 && annexes.length === 0) return null;

  const getDocument = (document: ApiContractDocument) => {
    switch (document.status) {
      case 'SENT':
        return (
          <ContractDocument.Sent document={document} contractId={contractId} />
        );
      case 'SIGNED':
        return (
          <ContractDocument.Signed
            document={document}
            contractId={contractId}
            isMigrated={isMigrated}
            user={user}
          />
        );
      case 'CANCELED':
      case 'PENDING':
        return null;
    }
  };

  const getAnnexDocument = (annex: ApiContractAnnex) => {
    switch (annex.status) {
      case 'PENDING_PAY':
        return <ContractAnnex.PendingPay annex={annex} />;
      case 'PENDING_SIGN':
        return <ContractAnnex.PendingSign annex={annex} />;
      case 'PENDING_CHECKS_IN':
        return <ContractAnnex.PendingChecksIn annex={annex} />;
      case 'SIGNED':
        return (
          <ContractAnnex.Signed
            annex={annex}
            contractId={contractId}
            user={user}
          />
        );
      case 'SENT':
        return (
          <ContractAnnex.Sent
            annex={annex}
            contractId={contractId}
            user={user}
          />
        );
    }
  };

  return (
    <div data-testid="CONTRACT_HISTORY" className='pt-12'>
      <h3 className="font-semibold text-xl text-neutral-700 mb-6">
        <FormattedMessage {...messages.contractHistory} />
      </h3>
      <div className="flex flex-col gap-4">
        {annexes.map(getAnnexDocument)}
        {documents.map(getDocument)}
      </div>
    </div>
  );
};
