import { createContext } from 'react';
import { System } from '@cooltra/utils';

export type PreferredSystemContextShape = {
  updateSystem: (system: System) => void;
  system: System;
};

export const PreferredSystemContext = createContext<
  PreferredSystemContextShape | undefined
>(undefined);
