import { FormattedMessage } from 'react-intl';
import { Button } from '@cooltra/ui';
import { useFormContext } from '@cooltra/form';

import { DepositFormValues } from '../../deposit-form';
import messages from '../messages';

import { StripeQRButton } from './StripeQRButton';

type SubmitButtonProps = {
  contractId: string;
  isLoading: boolean;
  onSubmit: (
    values: DepositFormValues,
    resetForm: (values: DepositFormValues) => void
  ) => Promise<void>;
  onCloseModal: () => void;
};

export const SubmitButton = ({
  onSubmit,
  isLoading,
  contractId,
  onCloseModal,
}: SubmitButtonProps) => {
  const { values, dirty, isValid, resetForm } =
    useFormContext<DepositFormValues>();
  const { amount, method, billingServicePointId } = values;
  const handleChargeSubmit = () =>
    onSubmit(
      {
        ...values,
        isCharge: true,
      },
      resetForm
    );
  const handleRetentionSubmit = async () =>
    onSubmit(
      {
        ...values,
        isCharge: false,
      },
      resetForm
    );

  const isSubmitButtonDisabled = !isValid || !dirty;

  if (method === 'TPV') {
    return (
      <>
        <Button
          onClick={handleChargeSubmit}
          emphasis="low"
          disabled={isSubmitButtonDisabled}
          loading={isLoading}
        >
          <FormattedMessage
            {...messages.markAsCharged}
            values={{
              amount,
            }}
          />
        </Button>
        <Button
          onClick={handleRetentionSubmit}
          emphasis="high"
          disabled={isSubmitButtonDisabled}
          loading={isLoading}
        >
          <FormattedMessage
            {...messages.markAsRetention}
            values={{
              amount,
            }}
          />
        </Button>
      </>
    );
  }

  if (method === 'STRIPE_QR') {
    return (
      <StripeQRButton
        contractId={contractId}
        amount={amount}
        onCloseModal={onCloseModal}
        billingServicePointId={billingServicePointId}
      />
    );
  }

  return (
    <Button
      onClick={handleRetentionSubmit}
      emphasis="high"
      disabled={isSubmitButtonDisabled}
      loading={isLoading}
    >
      <FormattedMessage
        {...messages.confirm}
        values={{
          amount,
        }}
      />
    </Button>
  );
};
