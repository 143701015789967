import { defineMessages } from 'react-intl';

export default defineMessages({
  noVehicles: {
    defaultMessage: "You don't have anything yet",
  },
  title: {
    defaultMessage: 'Vehicle type for {sale_type}',
  },
  addVehicle: {
    defaultMessage: 'Add vehicle type',
  },
  removeVehicle: {
    defaultMessage: 'Delete',
  },
  free: {
    defaultMessage: '(free)',
  },
  orSimilar: {
    defaultMessage: 'or similar',
  },
  removeVehicleNotification: {
    defaultMessage: 'Vehicle type removed successfully',
  },
});
