import { FormattedMessage } from 'react-intl';
import { List } from '@cooltra/ui';
import { BonusZone, useBonusZonesQuery, VehicleType } from '@cooltra/api';
import { System, classNames } from '@cooltra/utils';
import { PropsWithChildren } from 'react';

import { BonusZoneCard } from '../BonusZoneCard/BonusZoneCard';
import { NewBonusZoneLink } from '../NewBonusZoneLink/NewBonusZoneLink';

import messages from './messages';

const SublistTotal = (props: PropsWithChildren) => (
  <span className="block text-base text-neutral-400 mt-1 mb-6" {...props} />
);

export type BonusZonesProps = {
  system: System;
  vehicleType: VehicleType;
};

export const BonusZones = ({ system, vehicleType }: BonusZonesProps) => {
  const { isLoading, data, isError } = useBonusZonesQuery(system, {
    vehicleType,
  });

  const activeBonusZones =
    data?.bonusZones.filter(({ enabled }) => enabled) || [];

  const inactiveBonusZones =
    data?.bonusZones.filter(({ enabled }) => !enabled) || [];

  return (
    <div className="pb-4 pt-6">
      <div className="relative">
        <NewBonusZoneLink className="absolute top-0 right-0" />
      </div>
      <div>
        {isError && (
          <span className="text-neutral-600 text-sm block text-center pt-20">
            <FormattedMessage {...messages.error} />
          </span>
        )}
        {!isError && (
          <>
            {isLoading ? (
              <SublistTotal>
                <FormattedMessage {...messages.loading} />
              </SublistTotal>
            ) : activeBonusZones.length ? (
              <SublistTotal>
                <FormattedMessage
                  {...messages.activeBonusZones}
                  values={{
                    total: activeBonusZones.length,
                  }}
                />
              </SublistTotal>
            ) : null}
            <List
              isLoading={isLoading}
              idProp="bonusZoneId"
              className={classNames(
                'gap-5',
                !!activeBonusZones.length && 'mb-10'
              )}
              data={activeBonusZones}
              loadingRows={4}
              renderRow={(bonusZone: BonusZone) => (
                <BonusZoneCard {...bonusZone} system={system} />
              )}
            />
            {!!inactiveBonusZones.length && (
              <>
                <SublistTotal>
                  <FormattedMessage
                    {...messages.inactiveBonusZones}
                    values={{
                      total: inactiveBonusZones.length,
                    }}
                  />
                </SublistTotal>
                <List
                  isLoading={isLoading}
                  idProp="bonusZoneId"
                  data={inactiveBonusZones}
                  loadingRows={0}
                  renderRow={(bonusZone: BonusZone) => (
                    <BonusZoneCard
                      {...bonusZone}
                      deleteEnabled={true}
                      system={system}
                    />
                  )}
                />
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};
