import { FormattedMessage } from 'react-intl';
import { Badge } from '@cooltra/ui';
import { MdSync, MdSyncDisabled } from 'react-icons/md';
import { classNames } from '@cooltra/utils';

import messages from './messages';

export type SubscriptionContractStateProps = {
  autoRenew?: boolean;
  compact?: boolean;
};

export const SubscriptionContractState = ({
  autoRenew = true,
  compact = false,
}: SubscriptionContractStateProps) => {
  return (
    <Badge variant={autoRenew ? 'success' : 'danger'}>
      <span className="flex gap-1 items-center">
        {autoRenew ? (
          <MdSync className="text-base" />
        ) : (
          <MdSyncDisabled className="text-base" />
        )}
        <span className={classNames(compact && 'sr-only')}>
          <FormattedMessage {...messages.renew} />
        </span>
      </span>
    </Badge>
  );
};
