import { defineMessages } from 'react-intl';

export default defineMessages({
  unassignLicensePlate: {
    defaultMessage: 'Unassign license plate',
  },
  areYouSureToUnassignLicensePlate: {
    defaultMessage:
      'This action will unassign the license plate {licensePlate} to the contract. Do you want to continue?',
  },
  cancel: {
    defaultMessage: 'Cancel',
  },
  confirm: {
    defaultMessage: 'Confirm',
  },
  goesToWorkshop: {
    defaultMessage: 'This vehicle goes to workshop',
  },
});
