import { FormattedMessage, useIntl } from 'react-intl';
import { Legend, Link } from '@cooltra/ui';
import { System } from '@cooltra/utils';
import { useFormContext, FormRadioButtonGroupField } from '@cooltra/form';

import { CustomIcon } from '../../../CustomIcon';
import { SystemCountry } from '../../../System';
import * as DriverLicenseForm from '../../DriverLicenseForm';
import { ReviewFormValues } from '../review-form-types';
import { CardLayout } from '../CardLayout/CardLayout';

import messages from './messages';

export type DriverLicenseCheckProps = {
  system: System;
  userId: string;
};

export const DriverLicenseCheck = ({
  system,
  userId,
}: DriverLicenseCheckProps) => {
  const { formatMessage } = useIntl();
  const { values } = useFormContext<ReviewFormValues>();

  return (
    <>
      <CardLayout
        data-testid="DRIVER_LICENSE_CHECK"
        icon={<CustomIcon.Motorcycle />}
        heading={<FormattedMessage {...messages.heading} />}
      >
        <div className="grid gap-5">
          <FormRadioButtonGroupField
            legend={
              <Legend
                tooltipProps={{
                  interactive: true,
                  delay: [0, 200],
                }}
                tooltip={
                  <div className="max-w-sm">
                    <p className="text-sm font-normal leading-relaxed">
                      <FormattedMessage {...messages.tooltipBody} />
                    </p>
                    <p className="text-sm font-normal leading-relaxed">
                      <FormattedMessage
                        {...messages.tooltipHelp}
                        values={{
                          csTableLink: (
                            <Link
                              target="_blank"
                              href="https://cooltra-plugin.web.app/permisos"
                              className="text-sm font-semibold leading-relaxed"
                            >
                              <FormattedMessage {...messages.tooltipLink} />
                            </Link>
                          ),
                        }}
                      />
                    </p>
                  </div>
                }
              >
                <FormattedMessage
                  {...messages.isValidDriverLicense}
                  values={{ country: <SystemCountry system={system} /> }}
                />
              </Legend>
            }
            name="isValidDriverLicense"
            items={[
              {
                value: false,
                variant: 'invalid',
                label: formatMessage(messages.invalid),
                id: 'invalid-driver-license',
              },
              {
                value: true,
                variant: 'valid',
                label: formatMessage(messages.valid),
                id: 'valid-driver-license',
              },
            ]}
          />
          {values.isValidDriverLicense && (
            <>
              <DriverLicenseForm.ValidatedDriverLicenseNumber userId={userId} />
              <DriverLicenseForm.ExpirationDate />
              <DriverLicenseForm.Category />
              <DriverLicenseForm.DriverLicenseCountry />
            </>
          )}
        </div>
      </CardLayout>
    </>
  );
};
