import { FormSelectFieldProps, FormSelectField } from '@cooltra/form';
import { InputLabel } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { pricingGroupNames } from '@cooltra/station-based-api';

import messages from './messages';

export type POSPricingGroupSelectProps = Omit<
  FormSelectFieldProps,
  'name' | 'id' | 'label' | 'isClearable' | 'options'
>;

const options = Object.entries(pricingGroupNames).map(([key, name]) => ({
  value: key,
  label: name,
}));

export const POSPricingGroupSelect = (props: POSPricingGroupSelectProps) => {
  return (
    <FormSelectField
      id="price-list-pos-select"
      name="pricelistPOS"
      options={options}
      label={
        <InputLabel htmlFor="price-list-pos-select">
          <FormattedMessage {...messages.POSPricingGroup} />
        </InputLabel>
      }
      {...props}
    />
  );
};
