import { defineMessages } from 'react-intl';

export default defineMessages({
  backToList: {
    defaultMessage: 'Back',
  },
  general: {
    defaultMessage: 'General',
  },
  municipalityCode: {
    defaultMessage: 'Municipality code',
  },
  priceLists: {
    defaultMessage: 'Pricelists',
  },
  webPreferences: {
    defaultMessage: 'Web preferences',
  },
  minDays: {
    defaultMessage: 'Min days for contracts',
  },
  shouldBePositive: {
    defaultMessage: 'Should be positive',
  },
  invalidLength: {
    defaultMessage: 'Should be {length} characters long',
  },
  success: {
    defaultMessage: 'Service point details saved successfully',
  },
});
