import { FormattedMessage } from 'react-intl';
import { System, stringifyParams } from '@cooltra/utils';
import { RouterTab } from '@cooltra/navigation';
import { Route, Routes } from 'react-router-dom';
import { VehicleType } from '@cooltra/api';

import { Restrictions } from '../Restrictions';
import { BonusZones } from '../BonusZones';

import messages from './messages';

export type SelectedHomeSystemProps = {
  system: System;
  vehicleType: VehicleType;
};

export const SelectedHomeSystem = ({
  system,
  vehicleType,
}: SelectedHomeSystemProps) => (
  <div
    className="w-[40rem] p-4 h-full overflow-auto z-10 shadow-lg"
    data-testid="SELECTED_HOME_SYSTEM"
  >
    <div className="mt-4 flex flex-1 shrink-0 gap-10">
      <RouterTab to={`restrictions?${stringifyParams({ vehicleType })}`}>
        <FormattedMessage {...messages.restriction} />
      </RouterTab>
      <RouterTab to={`bonus-zones?${stringifyParams({ vehicleType })}`}>
        <FormattedMessage {...messages.bonusZone} />
      </RouterTab>
    </div>
    <Routes>
      <Route
        path="restrictions"
        element={<Restrictions system={system} vehicleType={vehicleType} />}
      />
      <Route
        path="bonus-zones"
        element={<BonusZones system={system} vehicleType={vehicleType} />}
      />
    </Routes>
  </div>
);
