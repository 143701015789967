import {
  Form,
  FormHelpers,
  FormMultiSelectField,
  FormProvider,
  FormSelectField,
} from '@cooltra/form';
import {
  useAccessoriesQuery,
  useAddVehicleTypeMutation,
  useVehicleTypesQuery,
} from '@cooltra/station-based-api';
import { Button, InputLabel } from '@cooltra/ui';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { getVehicleName, VehicleTypeName } from '@cooltra/utils';
import { captureMessage } from '@sentry/react';

import { useAccessoryName, useNotification } from '~/hooks';

import messages from './messages';
import {
  addVehicleFormInitialValues,
  AddVehicleFormValues,
  validateAddVehicleForm,
} from './add-vehicle-form';

const getVehicleTypeName = (name: string, intl: IntlShape) => {
  return `${getVehicleName(name as VehicleTypeName)} ${intl.formatMessage(messages.orSimilar)} `;
};
export type AddVehicleFormProps = {
  handleClose: VoidFunction;
  sale_type: string;
};

export const AddVehicleForm = ({
  handleClose,
  sale_type,
}: AddVehicleFormProps) => {
  const { servicePointId = '' } = useParams<'servicePointId'>();
  const intl = useIntl();
  const getAccessoryName = useAccessoryName();
  const { addErrorNotification, addSuccessNotification } = useNotification();
  console.log(sale_type);
  const { data: allAccessories } = useAccessoriesQuery({
    sale_type: sale_type,
  });
  const { data: allVehicleTypes } = useVehicleTypesQuery({
    sale_type: sale_type,
  });
  const { mutateAsync } = useAddVehicleTypeMutation(servicePointId);

  const vehiclesOptions = allVehicleTypes?.map((vehicleType) => ({
    label: getVehicleTypeName(vehicleType.name, intl),
    value: vehicleType.id,
  }));

  const accessoriesOptions = allAccessories?.map((accessory) => ({
    label: getAccessoryName(accessory.name),
    value: accessory.id,
  }));

  const onSubmit = async (
    values: AddVehicleFormValues,
    { resetForm }: FormHelpers<AddVehicleFormValues>
  ) =>
    mutateAsync(values)
      .then(() => {
        resetForm(values);
        addSuccessNotification(intl.formatMessage(messages.success));
        handleClose();
      })
      .catch((e) => {
        captureMessage('ERROR_ADDING_VEHICLE_TYPE', {
          extra: {
            error: e,
            values,
          },
        });
        addErrorNotification();
      });

  return (
    <FormProvider
      validate={validateAddVehicleForm(intl)}
      initialValues={addVehicleFormInitialValues}
      onSubmit={onSubmit}
    >
      <Form>
        <div className="flex flex-col gap-2 p-8 min-w-screen-sm max-w-screen-sm">
          <FormSelectField
            id="vehicle-type-id"
            label={
              <InputLabel htmlFor="vehicle-type-id">
                <FormattedMessage {...messages.vehicleTypeId} />
              </InputLabel>
            }
            name="vehicleTypeId"
            options={vehiclesOptions}
            placeholder={
              <FormattedMessage {...messages.vehicleTypeIdPlaceholder} />
            }
          />

          <FormMultiSelectField
            id="paid-accessories"
            label={
              <InputLabel htmlFor="paid-accessories">
                <FormattedMessage {...messages.paidAccessories} />
              </InputLabel>
            }
            name="paidAccessory"
            options={accessoriesOptions}
            placeholder={<FormattedMessage {...messages.noPaidAccessories} />}
          />

          <FormMultiSelectField
            id="free-accessories"
            label={
              <InputLabel htmlFor="free-accessories">
                <FormattedMessage {...messages.freeAccessories} />
              </InputLabel>
            }
            name="freeAccessory"
            options={accessoriesOptions}
            placeholder={<FormattedMessage {...messages.noFreeAccessories} />}
          />

          <Button
            type="submit"
            className="mx-auto px-14 mt-11"
            emphasis="high"
            data-testid="ADD_VEHICLE_TYPE_BUTTON"
          >
            <FormattedMessage {...messages.submit} />
          </Button>
        </div>
      </Form>
    </FormProvider>
  );
};
