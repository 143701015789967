import { IntlShape } from 'react-intl';

import { errorMessages, createSetError } from '~/validation';

export type AddVehicleFormValues = {
  vehicleTypeId: string;
  paidAccessory: string[];
  freeAccessory: string[];
};

export type AddVehicleFormFieldName = keyof AddVehicleFormValues;

export type AddVehicleFormErrors = Partial<
  Record<AddVehicleFormFieldName, string | undefined>
>;

export const validateRequired = (intl: IntlShape, value: any) =>
  value === '' || value === undefined
    ? intl.formatMessage(errorMessages.required)
    : undefined;

export const validateAddVehicleForm =
  (intl: IntlShape) =>
  (values: AddVehicleFormValues): AddVehicleFormErrors => {
    const errors: AddVehicleFormErrors = {};
    const setError = createSetError(errors);

    setError('vehicleTypeId', validateRequired(intl, values.vehicleTypeId));

    return errors;
  };

export const addVehicleFormInitialValues = {
  vehicleTypeId: '',
  paidAccessory: [],
  freeAccessory: [],
};
