import {
  Form,
  FormButton,
  FormProvider,
  FormCheckboxField,
} from '@cooltra/form';
import { Button, InputLabel, Modal } from '@cooltra/ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { useToggle } from '@cooltra/hooks';
import {
  Contract,
  useUnassignVehicleToContractMutation,
} from '@cooltra/station-based-api';

import { useNotification } from '~/hooks';

import messages from './messages';

export type UnassignLicensePlateProps = {
  contract: Contract;
  disabled?: boolean;
};

export const UnassignLicensePlate = ({
  contract: { contractId, vehicle, isSubscription },
  disabled = false,
}: UnassignLicensePlateProps) => {
  const [isOpen, { toggleOn, toggleOff }] = useToggle();
  const { formatMessage } = useIntl();
  const { addErrorNotification } = useNotification();

  const { mutateAsync, isPending } =
    useUnassignVehicleToContractMutation(contractId);

  type UnassignLicensePlateFormValues = {
    goesToWorkshop: boolean;
  };

  const initialValues: UnassignLicensePlateFormValues = {
    goesToWorkshop: false,
  };

  const onSubmit = async ({
    goesToWorkshop,
  }: UnassignLicensePlateFormValues) => {
    mutateAsync({
      goesToWorkshop,
    })
      .then(() => toggleOff())
      .catch(() => addErrorNotification());
  };

  return (
    <>
      <Button
        emphasis="low"
        variant="danger"
        onClick={toggleOn}
        disabled={disabled}
      >
        <FormattedMessage {...messages.unassignLicensePlate} />
      </Button>

      <Modal
        className="max-w-sm min-w-xs"
        isOpen={isOpen}
        onRequestClose={toggleOff}
      >
        <div
          data-testid="UNASSIGN_VEHICLE_CONFIRM_MODAL"
          className="text-center pb-8 px-8"
        >
          <h6 className="text-neutral-700 text-lg font-semibold mb-2">
            {formatMessage(messages.unassignLicensePlate)}
          </h6>
          <p className="text-neutral-600 text-sm mb-8">
            {formatMessage(messages.areYouSureToUnassignLicensePlate, {
              licensePlate: vehicle?.assignedVehicle?.licensePlate,
            })}
          </p>

          <FormProvider onSubmit={onSubmit} initialValues={initialValues}>
            <Form>
              <div className="flex flex-col justify-center gap-6">
                <div>
                  {isSubscription && (
                    <FormCheckboxField
                      className="flex justify-center"
                      id="goes-to-workshop"
                      label={
                        <InputLabel
                          emphasis="low"
                          htmlFor="goes-to-workshop"
                          className="text-neutral-600 text-sm mb-8"
                        >
                          <FormattedMessage {...messages.goesToWorkshop} />
                        </InputLabel>
                      }
                      name="goesToWorkshop"
                    />
                  )}
                </div>
                <div className="flex flex-row justify-center gap-3">
                  <Button onClick={toggleOff}>
                    <FormattedMessage {...messages.cancel} />
                  </Button>
                  <FormButton loading={isPending} disablePristine={false}>
                    <FormattedMessage {...messages.confirm} />
                  </FormButton>
                </div>
              </div>
            </Form>
          </FormProvider>
        </div>
      </Modal>
    </>
  );
};
