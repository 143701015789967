import {
  ContractServiceType,
  ContractStatus,
  SaleType,
} from '@cooltra/station-based-api';

export type FilterFormValues = {
  serviceType: ContractServiceType | '';
  servicePointId: string[];
  status: ContractStatus[];
  saleType: SaleType | '';
};

export const filterInitialFormValues: FilterFormValues = {
  serviceType: '',
  servicePointId: [],
  status: [],
  saleType: '',
};
