import {
  Contract,
  ContractSecondDriver,
  isBike,
} from '@cooltra/station-based-api';

export const isSecondDriverDataComplete = (
  secondDriver?: ContractSecondDriver | null
) => {
  if (!secondDriver) {
    return false;
  }

  const {
    driverLicenseNumber,
    driverLicenseCountry,
    photos,
    lastName,
    firstName,
  } = secondDriver;

  const isDriverLicenseComplete =
    !!driverLicenseNumber &&
    !!driverLicenseCountry &&
    !!photos?.driverLicenseFront &&
    !!photos?.driverLicenseBack;

  return !!firstName && !!lastName && isDriverLicenseComplete;
};

export const isTaxIdRequired = ({
  servicePointCountry,
  taxIdCountry,
}: {
  servicePointCountry?: string;
  taxIdCountry?: string;
}) => {
  if (!servicePointCountry || !taxIdCountry) {
    return false;
  }
  return (
    (servicePointCountry === 'IT' && taxIdCountry === 'IT') ||
    (servicePointCountry === 'PT' && taxIdCountry === 'PT')
  );
};

export const isDriversDataComplete = (
  { user, vehicle, accessories, secondDriver }: Contract,
  servicePointCountry?: string
) => {
  if (!user) {
    return false;
  }

  const {
    street,
    city,
    zip,
    country,
    dateOfBirth,
    idCardNumber,
    idCardExpirationDate,
    taxIdCountry,
    taxIdNumber,
    photos,
    driverLicenseCountry,
    driverLicenseNumber,
  } = user;

  const needsDriverLicense = !isBike(vehicle?.category);

  const isMainDriverPersonalDataComplete =
    !!street &&
    !!city &&
    !!zip &&
    !!country &&
    !!dateOfBirth &&
    !!idCardNumber &&
    !!idCardExpirationDate &&
    !!photos?.idCardFront &&
    !!taxIdCountry &&
    (isTaxIdRequired({
      servicePointCountry,
      taxIdCountry,
    })
      ? !!taxIdNumber
      : true);

  if (!needsDriverLicense) {
    return isMainDriverPersonalDataComplete;
  }

  const hasSecondDriver = accessories?.some(
    ({ name }) => name === 'CONDUCTOR_ADICIONAL'
  );

  const isMainDriverLicenseDataComplete =
    !!driverLicenseNumber &&
    !!driverLicenseCountry &&
    !!photos?.driverLicenseFront &&
    !!photos?.driverLicenseBack;

  if (!hasSecondDriver) {
    return isMainDriverPersonalDataComplete && isMainDriverLicenseDataComplete;
  }

  return (
    isMainDriverPersonalDataComplete &&
    isMainDriverLicenseDataComplete &&
    isSecondDriverDataComplete(secondDriver)
  );
};
