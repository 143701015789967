import { PropsWithChildren, useState } from 'react';
import { ViewStateChangeEvent } from 'react-map-gl';
import { VehicleType } from '@cooltra/api';
import { systemGeolocations, System } from '@cooltra/utils';
import { Placeholder } from '@cooltra/ui';

import { BaseMap, Geofence } from '~/common';
import { isTestingEnv } from '~/utils/e2e';

export type ZoneMapProps = PropsWithChildren<{
  system: System;
  vehicleType: VehicleType;
}>;

export const ZoneMap = ({ system, vehicleType, children }: ZoneMapProps) => {
  const [viewState, updateViewState] = useState({
    zoom: 11,
    ...systemGeolocations[system],
  });

  const onMove = ({
    viewState: { latitude, longitude, zoom },
  }: ViewStateChangeEvent) => updateViewState({ latitude, longitude, zoom });

  if (isTestingEnv()) {
    return <Placeholder className="w-full h-full" />;
  }

  return (
    <BaseMap
      style={{ width: '100%', height: '100%' }}
      onMove={onMove}
      {...viewState}
    >
      <Geofence system={system} vehicleType={vehicleType} />
      {children}
    </BaseMap>
  );
};
