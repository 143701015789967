import { FormattedMessage, useIntl } from 'react-intl';
import { FormSelectField } from '@cooltra/form';
import { InputLabel } from '@cooltra/ui';
import { SaleType } from '@cooltra/station-based-api';

import messages, { saleTypeMessages } from './messages';

type SaleTypeOption = {
  label: string;
  value: SaleType | '';
};

export const SaleTypeField = () => {
  const intl = useIntl();
  const saleTypeOptions: SaleTypeOption[] = [
    {
      label: intl.formatMessage(saleTypeMessages.ALL),
      value: '',
    },
    {
      label: intl.formatMessage(saleTypeMessages.SUBSCRIPTION),
      value: 'SUBSCRIPTION',
    },
    {
      label: intl.formatMessage(saleTypeMessages.SHORT_TERM),
      value: 'SHORT_TERM',
    },
  ];

  return (
    <FormSelectField
      id="saleType"
      name="saleType"
      label={
        <InputLabel htmlFor="saleType" className="shrink-0">
          <FormattedMessage {...messages.saleType} />
        </InputLabel>
      }
      options={saleTypeOptions}
      placeholder={intl.formatMessage(messages.all)}
    />
  );
};
