import { System } from '@cooltra/utils';
import { useIntl } from 'react-intl';

import { cities } from './messages';

export const useSystemLabel = () => {
  const { formatMessage } = useIntl();
  const getSystemLabel = (system: System) => formatMessage(cities[system]);
  return { getSystemLabel };
};
