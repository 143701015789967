import { FormattedMessage } from 'react-intl';
import { Contract, ContractPayment } from '@cooltra/station-based-api';
import { RouterTab } from '@cooltra/navigation';

import { PaidTicket } from '~/common';
import { useContractFlags } from '~/hooks';

import { TicketPendingPayment } from '../TicketPendingPayment/TicketPendingPayment';

import messages from './messages';

export type ContractPastChargesProps = {
  contract: Contract;
  payments: ContractPayment[];
};

export const ContractPastCharges = ({
  contract,
  payments,
}: ContractPastChargesProps) => {
  const { isSignedOrPendingSignature } = useContractFlags();

  const paidPayments = payments.filter(
    ({ status }) => status === 'PAID' || status === 'REFUNDED'
  );
  const awaitingPayments =
    payments.filter(({ status }) => status === 'AWAITING_PAYMENT') || [];

  if (!paidPayments.length && !awaitingPayments.length) {
    return null;
  }

  return (
    <>
      <div className="my-8">
        <RouterTab to=".">
          <FormattedMessage {...messages.pastCharges} />
        </RouterTab>
      </div>
      <div data-testid="CONTRACT_PAST_CHARGES" className="flex flex-col gap-8">
        {awaitingPayments.map((awaitingPaymentTicket) => (
          <TicketPendingPayment
            contractId={contract.contractId}
            ticket={awaitingPaymentTicket}
            key={awaitingPaymentTicket.id}
          />
        ))}
        {paidPayments.map((paidPayment) => (
          <PaidTicket
            refundDisabled={isSignedOrPendingSignature}
            key={paidPayment.id}
            contractId={contract.contractId}
            contractStatus={contract.status}
            payment={paidPayment}
            isSubscription={contract.isSubscription}
          />
        ))}
      </div>
    </>
  );
};
