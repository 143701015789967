import { Source } from 'react-map-gl';
import { useGeofenceQuery, VehicleType } from '@cooltra/api';
import { System } from '@cooltra/utils';

import { getGeofenceMapSource } from './get-geofence-map-sources';

export type GeofenceProps = {
  system: System;
  variant?: 'final' | 'original';
  vehicleType: VehicleType;
};

export const Geofence = ({
  system,
  variant = 'final',
  vehicleType,
}: GeofenceProps) => {
  const { data: geofence } = useGeofenceQuery(system, { vehicleType });

  if (!geofence) {
    return <></>;
  }

  const finalGeofence = geofence[variant];
  const geofencesMapSource = getGeofenceMapSource(finalGeofence, vehicleType);

  return (
    <>
      <Source
        id={geofencesMapSource.id}
        type="geojson"
        data={{
          type: 'FeatureCollection',
          features: geofencesMapSource.data,
        }}
      >
        {geofencesMapSource.layers}
      </Source>
    </>
  );
};
