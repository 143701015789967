import { FormSelectFieldProps, FormSelectField } from '@cooltra/form';
import { InputLabel } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { DestinationOptions } from '@cooltra/station-based-api';

import messages from './messages';

export type DestinationSelectFieldProps = Omit<
  FormSelectFieldProps,
  'name' | 'id' | 'label' | 'isClearable' | 'options'
>;

export const DestinationSelectField = (props: DestinationSelectFieldProps) => {
  return (
    <FormSelectField
      id="destination-field"
      name="destination"
      options={DestinationOptions}
      isClearable
      label={
        <InputLabel htmlFor="destination-field">
          <FormattedMessage {...messages.destination} />
        </InputLabel>
      }
      {...props}
    />
  );
};
