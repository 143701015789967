import { ServiceTypeField } from './ServiceTypeField';
import { ServicePointIdField } from './ServicePointIdField';
import { StatusField } from './StatusField';
import { SaleTypeField } from './SaleTypeField';

export const ModalFilterFields = {
  ServiceTypeField,
  ServicePointIdField,
  StatusField,
  SaleTypeField,
};
