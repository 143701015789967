import { FormCheckboxField } from '@cooltra/form';
import { InputLabel } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

export const ClickAndRideCheck = () => {
  return (
    <FormCheckboxField
      id="has-click-and-ride"
      name="hasClickAndRide"
      label={
        <InputLabel emphasis="low" htmlFor="has-click-and-ride">
          <FormattedMessage {...messages.clickAndRideCheck} />
        </InputLabel>
      }
    />
  );
};
