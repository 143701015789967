import { Layer, Marker, Source } from 'react-map-gl';
import { BonusZone as BonusZoneModel } from '@cooltra/api';
import { System } from '@cooltra/utils';

import { Circle } from '../../Circle/Circle';
import { MapLine } from '../MapLine/MapLine';

import {
  getBonusZoneBordersSourceId,
  getBonusZoneFillLayerId,
  getBonusZoneSourceId,
} from './get-bonus-zone-layer-id';

export type BonusZoneStyle = {
  fillColor: string;
  fillOutlineColor: string;
  lineColor: string;
};

export type BonusZoneProps = {
  bonusZone: BonusZoneModel;
  system: System;
  styles?: BonusZoneStyle;
  withNorthernPointMarker?: boolean;
};

export const BonusZone = ({
  bonusZone,
  system,
  styles = {
    fillColor: '#72879D',
    fillOutlineColor: '#333',
    lineColor: '#72879D',
  },
  withNorthernPointMarker,
}: BonusZoneProps) => {
  const bonusZoneCoordinates = bonusZone.polygon.coordinates[0];
  let northernPoint: null | { latitude: number; longitude: number } = null;

  if (withNorthernPointMarker) {
    for (const [longitude, latitude] of bonusZoneCoordinates) {
      if (!northernPoint || northernPoint.latitude < latitude) {
        northernPoint = {
          longitude,
          latitude,
        };
      }
    }
  }

  const isAValidCoordinate = (coordinate: any) =>
    typeof coordinate === 'number';

  const bonusZoneSourceId = getBonusZoneSourceId(bonusZone.bonusZoneId, system);
  const bonusZoneFillLayerId = getBonusZoneFillLayerId(bonusZone.bonusZoneId);

  const bonusZoneBordersSourceId = getBonusZoneBordersSourceId(
    bonusZone.bonusZoneId
  );

  return (
    <>
      {northernPoint &&
        isAValidCoordinate(northernPoint.latitude) &&
        isAValidCoordinate(northernPoint.longitude) && (
          <Marker
            latitude={northernPoint.latitude}
            longitude={northernPoint.longitude}
          >
            <Circle className="w-6 h-6 shrink-0 bg-neutral-400 text-neutral-0 text-base">
              <span className="relative -left-0.5">-€</span>
            </Circle>
          </Marker>
        )}
      <Source
        id={bonusZoneSourceId}
        type="geojson"
        data={{
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              properties: {},
              geometry: bonusZone.polygon as any,
            },
          ],
        }}
      >
        <Layer
          id={bonusZoneFillLayerId}
          source={bonusZoneSourceId}
          type="fill"
          paint={{
            'fill-translate': [0, 0],
            'fill-outline-color': styles?.fillOutlineColor,
            'fill-color': styles?.fillColor,
            'fill-opacity': [
              'interpolate',
              ['linear'],
              ['zoom'],
              7,
              0,
              12,
              0.1,
              22,
              0.3,
            ],
          }}
        />
      </Source>
      <MapLine
        sourceId={bonusZoneBordersSourceId}
        coordinates={bonusZoneCoordinates}
        paint={{
          'line-color': styles?.lineColor,
          'line-width': 2,
        }}
      />
    </>
  );
};
