import { Spinner } from '@cooltra/ui';
import { RouterCardLink } from '@cooltra/navigation';
import { FormattedMessage } from 'react-intl';
import { useFetchUsersInAwaitingReviewSummaryQuery } from '@cooltra/api';
import { allHomeSystemsList, System } from '@cooltra/auth-api';
import { differenceInMinutes } from 'date-fns';

import { SystemCity, UserStateBadge } from '~/common';

import messages from './messages';

export const UsersAwaitingReviewSummary = () => {
  const { data, isLoading, isError } =
    useFetchUsersInAwaitingReviewSummaryQuery();

  if (isLoading) {
    return (
      <div className="w-full text-center">
        <Spinner className="text-neutral-500" />
      </div>
    );
  }

  if (isError) {
    return (
      <p className="text-neutral-600 text-center">
        <FormattedMessage {...messages.tryAgainLater} />
      </p>
    );
  }

  if (!data) {
    return <></>;
  }

  const getAllowedHomeSystems = (): System[] => {
    return (allHomeSystemsList as System[]).filter((system) => {
      const notAllowedSystems: System[] = [
        'Berlin',
        'Malaga',
        'Mallorca',
        'Munich',
        'Zurich',
      ];

      return !notAllowedSystems.includes(system);
    });
  };

  const getUsersAwaitingReviewKeys = (): System[] => {
    const usersAwaitingReviewKeys = Object.keys(
      data.usersAwaitingReview
    ) as System[];
    const systemsWithZeroAwaitingReview = getAllowedHomeSystems().filter(
      (system) => usersAwaitingReviewKeys.indexOf(system) < 0
    );

    return [...usersAwaitingReviewKeys, ...systemsWithZeroAwaitingReview];
  };

  const getUsersAwaitingReview = () => {
    const usersAwaitingReviewKeys = getUsersAwaitingReviewKeys();

    return usersAwaitingReviewKeys
      .sort((system, nextSystem) => system.localeCompare(nextSystem))
      .map((usersAwaitingReviewKey) => (
        <RouterCardLink
          to={`/users-reviews?system=${usersAwaitingReviewKey}`}
          className="h-24 text-center flex flex-col justify-center"
          key={usersAwaitingReviewKey}
        >
          <h3 className="font-semibold text-xl">
            {data.usersAwaitingReview[usersAwaitingReviewKey] || 0}
          </h3>
          <span className="text-xs text-neutral-400">
            <SystemCity system={usersAwaitingReviewKey} />
          </span>
        </RouterCardLink>
      ));
  };

  return (
    <>
      <div className="flex items-center justify-between">
        <div className="flex-1">
          <UserStateBadge state="AWAITING_REVIEW" />
        </div>
        <div className="flex flex-col items-center">
          <span className="flex gap-1 items-center font-semibold text-4xl">
            {data.total}
            {data.total >= 1000 && (
              <span data-testid="USERS_AWAITING_REVIEW_FIRE">&#128293;</span>
            )}
          </span>
          <span className="font-semibold text-xs">
            <FormattedMessage {...messages.users} />
          </span>
        </div>
        <div className="flex flex-col items-end flex-1">
          {data.oldestUserCreatedAt && (
            <>
              <span className="text-xs text-neutral-500">
                <FormattedMessage {...messages.oldestUserWait} />
              </span>
              <span className="font-semibold text-sm">
                {differenceInMinutes(
                  new Date(),
                  new Date(data.oldestUserCreatedAt)
                )}
                min
              </span>
            </>
          )}
        </div>
      </div>
      <div className="w-full mt-8 grid grid-cols-4 gap-2">
        {getUsersAwaitingReview()}
      </div>
    </>
  );
};
