import { FormattedMessage } from 'react-intl';
import { System } from '@cooltra/utils';

import { cities } from './messages';

export type SystemCityProps = {
  system: System;
};

export const SystemCity = ({ system }: SystemCityProps) => (
  <FormattedMessage {...cities[system]} />
);
