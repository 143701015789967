import { Modal } from '@cooltra/ui';
import { useToggle } from '@cooltra/hooks';

import { FiltersButton } from '../FiltersButton/FiltersButton';
import { useFilters } from '../../useFilters';

import {
  FilterFormProvider,
  filterInitialFormValues,
} from './FilterFormProvider';
import { ShowContractLink } from './ShowContractLink';
import { ModalFilterFields } from './Fields';
import { ClearFiltersButton } from './ClearFiltersButton';

export const FiltersModal = () => {
  const [isOpen, { toggleOff, toggleOn }] = useToggle();
  const { servicePointId, serviceType, status, saleType } = useFilters();

  return (
    <FilterFormProvider
      initialValues={{
        ...filterInitialFormValues,
        serviceType: serviceType || '',
        servicePointId,
        status,
        saleType: saleType || '',
      }}
    >
      <FiltersButton className="mr-6" onClick={toggleOn} />
      <Modal
        isOpen={isOpen}
        onRequestClose={toggleOff}
        className="w-full max-w-screen-md mb-14"
      >
        <div className="px-8 pb-8">
          <div className="grid grid-cols-5 gap-8">
            <div className="col-span-2">
              <ModalFilterFields.ServiceTypeField />
            </div>
            <div className="col-span-3">
              <ModalFilterFields.StatusField />
            </div>
            <div className="col-span-3">
              <ModalFilterFields.ServicePointIdField />
            </div>
            <div className="col-span-2">
              <ModalFilterFields.SaleTypeField />
            </div>
          </div>
          <div className="flex mt-12 gap-12 items-center justify-end">
            <ClearFiltersButton />
            <ShowContractLink onClick={toggleOff} />
          </div>
        </div>
      </Modal>
    </FilterFormProvider>
  );
};
