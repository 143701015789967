import { FormattedMessage, useIntl } from 'react-intl';
import {
  ContractPayment,
  useContractPaymentsQuery,
  useInvoicePdfQuery,
  usePayTicketContractMutation,
} from '@cooltra/station-based-api';
import { classNames } from '@cooltra/utils';
import { MdChevronRight, MdError, MdReplay } from 'react-icons/md';
import { useToggle } from '@cooltra/hooks';
import { Button, Card } from '@cooltra/ui';
import { parse } from 'date-fns';
import { useCallback, useEffect } from 'react';
import { AxiosError } from 'axios';
import { getErrorStatus } from '@cooltra/axios';

import {
  Amount,
  ContractPaymentItems,
  DateTime,
  StripeCustomerLink,
  TextSeparator,
} from '~/common';
import { useNotification, usePolling } from '~/hooks';

import messages from './messages';

export type SubscriptionTicketProps = {
  payment: ContractPayment;
  contractId: string;
};

export const SubscriptionTicket = ({
  payment,
  contractId,
}: SubscriptionTicketProps) => {
  const {
    totalPrice: { currency, value },
    yearMonth,
    status,
    paidAt,
    paymentMethodDetails,
    invoiceId,
    failureMessage,
    updatedAt,
    id,
    markedAsPaidIn,
  } = payment;

  const [isExpanded, { toggle }] = useToggle();
  const { addErrorNotification } = useNotification();
  const payTicketMutation = usePayTicketContractMutation(contractId, id);
  const { data: payments, refetch: refetchPayments } =
    useContractPaymentsQuery(contractId);

  const payTicket = async () => {
    payTicketMutation
      .mutateAsync({
        paymentMethod: 'CARD',
        billingServicePointId: markedAsPaidIn!,
        operationNumber: null,
        lastFourDigits: null,
      })
      .catch(() => addErrorNotification());
  };

  const { isPolling: isPayTicketPolling } = usePolling({
    fn: refetchPayments,
    onSettle: () => {
      payTicketMutation.reset();
      if (payTicketMutation.error) {
        addErrorNotification();
      }
    },
    maxAttempts: 5,
    interval:
      payTicketMutation.isSuccess &&
      payments?.find(({ id: paymentId }) => id === paymentId)?.status !== 'PAID'
        ? 1000
        : 0,
  });

  const intl = useIntl();

  const { formatMessage } = useIntl();
  const {
    fetchStatus: invoiceFetchStatus,
    refetch: invoiceRefetch,
    error: invoiceError,
  } = useInvoicePdfQuery(invoiceId || '', {
    enabled: false,
  });

  const checkError = useCallback(
    (error: AxiosError) => {
      const errorMessage =
        getErrorStatus(error) === 404
          ? formatMessage(messages.pdfDoesNotExist)
          : formatMessage(messages.somethingWentWrongOnDownloadingPdf);
      addErrorNotification(errorMessage);
    },
    [addErrorNotification, formatMessage]
  );

  useEffect(() => {
    if (invoiceError) {
      checkError(invoiceError);
    }
  }, [addErrorNotification, invoiceError, formatMessage, checkError]);

  const date = parse(yearMonth!, 'yyyy-MM', Date());
  const formattedDate = intl.formatDate(date, {
    year: 'numeric',
    month: 'long',
  });

  return (
    <Card className="p-8">
      <div
        className={classNames(
          'flex group items-center justify-between',
          isExpanded && 'is-expanded'
        )}
      >
        <div>
          <h2 className="text-lg text-neutral-800 mb-2">
            <FormattedMessage
              {...messages.subscription}
              values={{ yearMonth: formattedDate }}
            />
          </h2>

          {status === 'PAID' ? (
            <div className="flex items-center gap-3 text-neutral-500">
              <DateTime
                date={paidAt}
                dateClassName="text-neutral-500 text-base"
                timeClassName="text-neutral-500 text-base"
              />
              <TextSeparator />
              <StripeCustomerLink paymentMethod={paymentMethodDetails!} />
              {invoiceId && (
                <Button
                  size="sm"
                  loading={invoiceFetchStatus === 'fetching'}
                  onClick={() => invoiceRefetch()}
                >
                  <FormattedMessage {...messages.invoicePdf} />
                </Button>
              )}
            </div>
          ) : status === 'FAILED' ? (
            <>
              <div className="text-danger-500 text-base mb-3 flex flex-column gap-1 items-center">
                <MdError className="size-5" />
                <FormattedMessage
                  {...messages.unpaid}
                  values={{ errorMessage: failureMessage }}
                />
              </div>
              <div className="flex items-center gap-3 text-neutral-500">
                <DateTime
                  date={updatedAt}
                  dateClassName="text-neutral-500 text-base"
                  timeClassName="text-neutral-500 text-base"
                />
                <TextSeparator />
                <StripeCustomerLink paymentMethod={paymentMethodDetails!} />
                {invoiceId && (
                  <Button
                    size="sm"
                    loading={invoiceFetchStatus === 'fetching'}
                    onClick={() => invoiceRefetch()}
                  >
                    <FormattedMessage {...messages.invoicePdf} />
                  </Button>
                )}
              </div>
            </>
          ) : (
            <span className="text-base text-warning-700">
              <FormattedMessage {...messages.pendingCharge} />
            </span>
          )}
        </div>
        <div className="flex items-center gap-3 self-end">
          {status === 'FAILED' && (
            <Button
              size="sm"
              loading={payTicketMutation.isPending || isPayTicketPolling}
              onClick={payTicket}
            >
              <div className="flex items-center gap-1">
                <MdReplay className="size-5" />
                <FormattedMessage {...messages.retry} />
              </div>
            </Button>
          )}
          <Amount
            value={value}
            currency={currency}
            className={'text-xl font-semibold text-neutral-800'}
          />
          <button
            aria-label={formatMessage(messages.expandSummary)}
            className="rotate-90 transition duration-150 group-[.is-expanded]:rotate-270"
            onClick={toggle}
          >
            <MdChevronRight className="text-3xl text-neutral-800" />
          </button>
        </div>
      </div>
      {isExpanded && (
        <div className="mt-8">
          <ContractPaymentItems.Vehicle className="mb-2" payment={payment} />
          <ContractPaymentItems.Coverage
            className="mb-2 mt-6 pt-6 border-t border-neutral-100"
            payment={payment}
            isSubscription={true}
          />
          <ContractPaymentItems.Accessories
            className="mb-2 mt-6 pt-6 border-t border-neutral-100"
            payment={payment}
          />
          <ContractPaymentItems.Penalties
            className="mb-2 mt-6 pt-6 border-t border-neutral-100"
            payment={payment}
          />
          <ContractPaymentItems.ValueAdjustment
            contractId={contractId}
            className="mb-2 mt-6 pt-6 border-t border-neutral-100"
            payment={payment}
          />
          <ContractPaymentItems.Deposit
            className="mb-2 mt-6 pt-6 border-t border-neutral-100"
            payment={payment}
          />
          <ContractPaymentItems.Total
            className="mt-6 pt-6 border-t border-neutral-100"
            payment={payment}
          />
        </div>
      )}
    </Card>
  );
};
