import { Badge, Card, Divider } from '@cooltra/ui';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Contract,
  ContractPayment,
  SubscriptionPlan,
  useCancelContractRenewalMutation,
} from '@cooltra/station-based-api';
import { differenceInCalendarMonths, format, parse } from 'date-fns';
import { MdError } from 'react-icons/md';
import { Form, FormProvider } from '@cooltra/form';

import { getContractTermMessage } from '~/utils/contract';
import {
  Amount,
  ContractOverviewDates,
  ContractOverviewExtras,
  ContractOverviewHistory,
  ContractOverviewUser,
  ContractOverviewVehicle,
  ContractTypeLabel,
  Log,
  StripeCustomerLink,
  SubscriptionContractState,
} from '~/common';
import { dateFormat } from '~/utils/date';
import { useNotification } from '~/hooks';

import { CancelRenewalButton } from '../CancelRenewalButton/CancelRenewalButton';

import messages, { planLabels } from './messages';
import {
  CancelSubscriptionRenewalFormValues,
  getInitialCancelSubscriptionRenewalFormValues,
} from './subscription-contract-overview-form';
import { SubscriptionDropOffDateTime } from './SubscriptionDropOffDateTime/SubscriptionDropOffDateTime';

export type SubscriptionContractOverviewProps = {
  contract: Contract;
  payments: ContractPayment[];
};

export const getContractPlanMessage = (plan: SubscriptionPlan) =>
  planLabels[plan];

export const SubscriptionContractOverview = ({
  contract,
  payments,
}: SubscriptionContractOverviewProps) => {
  const intl = useIntl();
  const { addErrorNotification } = useNotification();

  const {
    monthsTerm,
    details,
    type,
    createdAt,
    createdBy,
    updatedAt,
    updatedBy,
    user,
    plan,
    monthlyCharge,
    vehicle,
    status,
    insurance,
    accessories,
    nextRenewalDate,
    linkedPaymentMethod,
    autoRenew,
  } = contract;
  const { formatMessage } = useIntl();
  const unpaidTickets = payments.filter(
    (payment) => payment.status === 'FAILED'
  ).length;

  const { mutateAsync } = useCancelContractRenewalMutation(contract.contractId);

  const handleSubmit = (values: CancelSubscriptionRenewalFormValues) => {
    return mutateAsync({
      endDate: format(
        parse(values.endDate, dateFormat, new Date()),
        'yyyy-MM-dd'
      ),
      endTime: values.endTime,
    }).catch(() => addErrorNotification());
  };

  return (
    <div
      className="mx-auto max-w-4xl pt-4 pb-10"
      data-testid="SUBSCRIPTION_CONTRACT_OVERVIEW"
    >
      <div className="grid grid-cols-2 gap-6 mb-6">
        <Card
          className="p-8"
          data-testid="SUBSCRIPTION_CONTRACT_OVERVIEW_TERMS"
        >
          <span className="block text-xs text-neutral-500 font-medium mb-2">
            <FormattedMessage {...messages.subscription} />
          </span>
          <span className="block text-2xl font-semibold text-neutral-800 mb-2">
            <FormattedMessage {...getContractTermMessage(monthsTerm)} />
          </span>
          <div className="flex gap-2">
            <Badge key={plan} emphasis="low">
              {formatMessage(getContractPlanMessage(plan!))}
            </Badge>
            <SubscriptionContractState autoRenew={!!autoRenew} />
          </div>
          <div>
            <div className="flex justify-between gap-8 mt-6">
              <div>
                <span className="block text-xs text-neutral-500 mb-1">
                  <FormattedMessage {...messages.totalDuration} />
                </span>
                <span className="text-base text-neutral-800">
                  <FormattedMessage
                    {...messages.months}
                    values={{
                      total: Math.ceil(
                        differenceInCalendarMonths(
                          new Date(),
                          new Date(
                            `${details!.startDate}T${details!.startTime}`
                          )
                        ) + 1
                      ),
                    }}
                  />
                </span>
              </div>
              <div className="flex flex-col">
                <span className="block text-xs text-neutral-500 mb-1">
                  <FormattedMessage {...messages.nextReview} />
                </span>
                {nextRenewalDate && (
                  <span className="text-base text-neutral-800 self-end">
                    {intl.formatDate(nextRenewalDate, {
                      day: '2-digit',
                      month: 'short',
                      year: 'numeric',
                    })}
                  </span>
                )}
              </div>
            </div>
          </div>
        </Card>

        <Card
          className="p-8"
          data-testid="SUBSCRIPTION_CONTRACT_OVERVIEW_CHARGES"
        >
          <div className="flex flex-col justify-between h-full">
            <div>
              <span className="block text-xs text-neutral-500 font-medium mb-2">
                <FormattedMessage {...messages.monthlyCharge} />
              </span>
              <span className="flex items-center gap-1 mb-3 text-neutral-800 text-2xl font-semibold">
                {monthlyCharge ? (
                  <Amount
                    value={monthlyCharge!.value}
                    currency={monthlyCharge!.currency}
                  />
                ) : (
                  <span className="text-neutral-800 text-xl font-semibold">
                    -
                  </span>
                )}
              </span>
              {unpaidTickets > 0 && (
                <div className="flex items-center gap-1">
                  <MdError className="size-5 text-danger-500" />
                  <span className="text-neutral-700">
                    <FormattedMessage
                      {...messages.unpaidTickets}
                      values={{ unpaidTickets }}
                    ></FormattedMessage>
                  </span>
                </div>
              )}
            </div>
            <div className="flex justify-between gap-8 mt-6">
              <div className="flex flex-col">
                <span className="block text-xs text-neutral-500 mb-1">
                  <FormattedMessage {...messages.savedCreditCard} />
                </span>
                {linkedPaymentMethod && (
                  <span className="text-base text-neutral-800">
                    <StripeCustomerLink paymentMethod={linkedPaymentMethod} />
                  </span>
                )}
              </div>
            </div>
          </div>
        </Card>
      </div>
      <Card
        className="flex"
        data-testid="SUBSCRIPTION_CONTRACT_OVERVIEW_DETAILS"
      >
        {type && <ContractTypeLabel type={type} />}
        <div className="w-48 lg:w-60 shrink-0 px-8 lg:px-10 py-8 bg-neutral-100/20 flex flex-col gap-64">
          {user && <ContractOverviewUser user={user} />}
        </div>
        <div className="w-full p-8">
          {details && <ContractOverviewDates details={details} />}
          {vehicle && <Divider />}
          <ContractOverviewVehicle vehicle={vehicle} status={status} />
          {(accessories || insurance) && <Divider />}
          <ContractOverviewExtras
            insurance={insurance}
            accessories={accessories}
            isSubscription={contract.isSubscription}
          />
        </div>
      </Card>

      <div className="flex pt-5 gap-10">
        {createdAt && (
          <div className="max-w-56 truncate">
            <Log
              action="create"
              user="operator"
              userId={createdBy}
              date={createdAt}
            />
          </div>
        )}
        {updatedAt && updatedBy && (
          <>
            <div className="py-1">
              <Divider direction="vertical" className="h-full" />
            </div>
            <div className="max-w-56 truncate">
              <Log
                action="update"
                user="operator"
                userId={updatedBy}
                date={updatedAt}
              />
            </div>
          </>
        )}
      </div>

      <ContractOverviewHistory contract={contract} />

      {['ACTIVE', 'CLOSED'].includes(contract.status) && (
        <div className="pt-8">
          <h3 className="font-semibold text-xl text-neutral-700 mb-6">
            <FormattedMessage {...messages.subscriptionCancellation} />
          </h3>
          <Card
            className="p-8 flex flex-col justify-between h-full w-1/2"
            data-testid="SUBSCRIPTION_CONTRACT_CANCELLATION"
          >
            <div>
              <FormProvider
                initialValues={getInitialCancelSubscriptionRenewalFormValues(
                  contract
                )}
                onSubmit={handleSubmit}
              >
                <Form>
                  <div className="mx-auto max-w-4xl pb-4 flex flex-col gap-3">
                    <SubscriptionDropOffDateTime />
                  </div>
                  <CancelRenewalButton contract={contract} />
                </Form>
              </FormProvider>
            </div>
          </Card>
        </div>
      )}
    </div>
  );
};
