import { defineMessages } from 'react-intl';

export default defineMessages({
  cancelRenewal: {
    defaultMessage: 'Cancel and send email',
  },
  confirmCancelRenewalTitle: {
    defaultMessage: 'Cancel this subscription?',
  },
  confirmCancelRenewalContent: {
    defaultMessage:
      "We'll send the client an email confirming this contract won't be renewed.",
  },
  confirmCancelRenewalClose: {
    defaultMessage: 'Close',
  },
  confirmCancelRenewalConfirm: {
    defaultMessage: 'Cancel subscription',
  },
  updateAndSendEmail: {
    defaultMessage: 'Update and send email',
  },
  successCancelRenewal: {
    defaultMessage: 'Subscription renewal cancelled successfully',
  },
  successUpdateDropOffAppointment: {
    defaultMessage: 'Drop off appointment updated successfully',
  },
});
