import { Card } from '@cooltra/ui';
import {
  Contract,
  SubscriptionAvailableAccessory,
} from '@cooltra/station-based-api';
import { accessoryImages } from '@cooltra/utils';
import { FormattedMessage } from 'react-intl';

import { useAccessoryName, useSubscriptionContractFlags } from '~/hooks';
import {
  AccessoryCounter,
  AddAccessory,
  Amount,
  ExtraAdded,
  RemoveAccessory,
} from '~/common';
import { isCustomDelivery, isCustomReturn } from '~/utils/accessories';

import messages from './messages';

export type SubscriptionAccessoryCardProps = SubscriptionAvailableAccessory & {
  contract: Contract;
  quantity?: number;
  info?: string;
};

export const SubscriptionAccessoryCard = ({
  contract,
  accessoryId,
  quantity = 0,
  name,
  monthlyPrice,
  limit,
  info,
}: SubscriptionAccessoryCardProps) => {
  const getAccessoryName = useAccessoryName();
  const { isSignedOrPendingSignature } = useSubscriptionContractFlags();

  const getCanEditAccessory = () => {
    if (isSignedOrPendingSignature) {
      return false;
    }

    if (contract.status === 'ACTIVE') {
      return false;
    }

    const contractIncludesAccessory = !!contract.accessories?.find(
      ({ id }) => id === accessoryId
    );

    if (!contractIncludesAccessory) {
      return true;
    }

    return true;
  };

  const canEditAccessory = getCanEditAccessory();

  return (
    <Card data-testid={`ACCESSORY_${name}`} className="bg-neutral-0 p-8">
      <div>
        <h2 className="mb-4 text-lg font-semibold text-neutral-800">
          {getAccessoryName(name)}
        </h2>
      </div>
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <img className="w-24 mr-6" src={accessoryImages[name]} alt={name} />
          <div className="flex flex-col overflow-hidden">
            <div className="flex items-baseline gap-2">
              <span className="mb-2 text-3xl font-semibold text-neutral-800">
                <Amount {...monthlyPrice} />
              </span>
              {!isCustomReturn(name) && !isCustomDelivery(name) && (
                <span className="text-base font-medium text-neutral-600 truncate">
                  <FormattedMessage {...messages.perMonth} />
                </span>
              )}
            </div>
            {info && (
              <span className="block text-neutral-500 text-sm">{info}</span>
            )}
          </div>
        </div>
      </div>
      <div className="mt-6 flex items-center justify-end h-11">
        {limit === 1 ? (
          quantity === 1 ? (
            <div className="flex w-full justify-between gap-2 items-center">
              <RemoveAccessory
                disabled={!canEditAccessory}
                accessoryId={accessoryId}
                name={name}
                isSubscription={true}
              />
              <ExtraAdded />
            </div>
          ) : (
            <AddAccessory
              disabled={!canEditAccessory}
              name={name}
              accessoryId={accessoryId}
              isSubscription={true}
            />
          )
        ) : (
          <AccessoryCounter
            disabled={!canEditAccessory}
            accessoryId={accessoryId}
            quantity={quantity}
            limit={limit}
            isSubscription={true}
          />
        )}
      </div>
    </Card>
  );
};
