import { FormattedMessage, useIntl } from 'react-intl';
import {
  ContractPayment,
  useCancelSubscriptionAwaitingPaymentTicketMutation,
  useContractPaymentsQuery,
} from '@cooltra/station-based-api';
import { Button, Card } from '@cooltra/ui';
import { MdQrCode } from 'react-icons/md';
import { useToggle } from '@cooltra/hooks';

import { useQRPayment } from '~/libs/qr-payment';
import { useNotification } from '~/hooks';
import { Amount, ConfirmModal, DateTime, TextSeparator } from '~/common';

import messages from './messages';

export type TicketPendingPaymentProps = {
  contractId: string;
  ticket: ContractPayment;
};

export const TicketPendingPayment = ({
  contractId,
  ticket: { id, totalPrice, markedAsAwaitingPaymentAt },
  ...rest
}: TicketPendingPaymentProps) => {
  const { formatMessage } = useIntl();
  const { refetch, isRefetching } = useContractPaymentsQuery(contractId, {
    enabled: false,
  });
  const { openModal } = useQRPayment();
  const verifyDeposit = () => refetch();
  const openQR = () => openModal({ id, isDeposit: false });

  const [isOpen, { toggleOn, toggleOff }] = useToggle(false);
  const { mutateAsync, isPending } =
    useCancelSubscriptionAwaitingPaymentTicketMutation(contractId, id);
  const { addSuccessNotification, addErrorNotification } = useNotification();

  const cancelAwaitingPayment = () =>
    mutateAsync()
      .then(() => {
        addSuccessNotification(formatMessage(messages.success));
        toggleOff();
      })
      .catch(() => addErrorNotification());

  return (
    <Card className="p-8">
      <div
        className="flex justify-between items-end"
        data-testid="AWAITING_PAYMENT_CONTAINER"
        {...rest}
      >
        <div className="flex flex-col gap-3">
          <span className="font-medium text-lg">
            <FormattedMessage {...messages.title} />
          </span>
          <span className="text-warning-600">
            <FormattedMessage {...messages.pendingPayment} />
          </span>
          <div className="flex items-center gap-3 text-neutral-500">
            <span className="flex items-center gap-1">
              <FormattedMessage {...messages.createdAt} />
              <DateTime
                date={markedAsAwaitingPaymentAt}
                dateClassName="text-base text-neutral-500"
                timeClassName="text-base text-neutral-500"
              />
            </span>
            <TextSeparator />
            <Button
              onClick={openQR}
              emphasis="low"
              size="sm"
              leadingIcon={<MdQrCode />}
            >
              <FormattedMessage {...messages.showStripeQR} />
            </Button>
          </div>
        </div>
        <div className="flex flex-col gap-3 items-end">
          <Amount
            className="font-semibold text-xl text-neutral-800"
            {...totalPrice}
          />
          <div className="flex items-center gap-3">
            <Button
              onClick={toggleOn}
              emphasis="low"
              size="sm"
              variant="danger"
            >
              <FormattedMessage {...messages.cancel} />
            </Button>
            <Button loading={isRefetching} size="sm" onClick={verifyDeposit}>
              <FormattedMessage {...messages.verify} />
            </Button>
          </div>
        </div>
      </div>
      <ConfirmModal
        className="max-w-md min-w-xs pb-3"
        isOpen={isOpen}
        title={formatMessage(messages.cancelPendingTicketTitle)}
        content={
          <p className="px-12 mt-7 mb-9 text-neutral-700 text-md">
            <FormattedMessage {...messages.cancelPendingTicketContent} />
          </p>
        }
        confirmText={<FormattedMessage {...messages.confirm} />}
        onConfirm={cancelAwaitingPayment}
        onClose={toggleOff}
        loading={isPending}
      />
    </Card>
  );
};
