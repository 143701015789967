import { Card } from '@cooltra/ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { useGeofenceRestrictionMutation } from '@cooltra/api';
import { System, stringifyParams } from '@cooltra/utils';
import { useNavigate, useParams } from 'react-router-dom';
import { FormButton } from '@cooltra/form';

import { RouterBackLink } from '~/common';
import { useNotification } from '~/hooks';

import {
  RestrictionForm,
  RestrictionFormValues,
  getInitialFormValues,
} from '../../RestrictionForm';
import * as ZoneFields from '../../ZoneFields';
import { ZoneMap } from '../../ZoneMap/ZoneMap';
import { RestrictionPreview } from '../RestrictionPreview';
import { useVehicleTypeQueryParam } from '../../utils';

import messages from './messages';

export const NewRestriction = () => {
  const { formatMessage } = useIntl();
  const params = useParams<'system'>();
  const system = params.system as System;

  const vehicleType = useVehicleTypeQueryParam();

  const navigate = useNavigate();
  const { addErrorNotification, addSuccessNotification } = useNotification();

  const { mutateAsync } = useGeofenceRestrictionMutation(system);

  const handleSubbmit = (values: RestrictionFormValues) =>
    mutateAsync(values)
      .then(() => {
        addSuccessNotification(formatMessage(messages.success));
        navigate(
          `/zones/${system}/restrictions?${stringifyParams({ vehicleType })}`
        );
      })
      .catch(() => addErrorNotification());

  return (
    <div className="container max-w-3xl py-12">
      <div className="mb-9">
        <RouterBackLink to={`/zones/${system}/restrictions`}>
          <FormattedMessage {...messages.back} />
        </RouterBackLink>
        <h2 className="text-3xl">
          <FormattedMessage {...messages.restriction} />
        </h2>
      </div>
      <RestrictionForm
        initialValues={getInitialFormValues(vehicleType)}
        onSubmit={handleSubbmit}
      >
        <Card className="mb-5 p-10">
          <div className="flex flex-col gap-y-6">
            <ZoneFields.Title />
            <ZoneFields.Polygon />
            <div className="h-96 w-full rounded-lg overflow-hidden">
              <ZoneMap system={system} vehicleType={vehicleType}>
                <RestrictionPreview />
              </ZoneMap>
            </div>
          </div>
        </Card>
        <div className="flex justify-end">
          <FormButton>
            <FormattedMessage {...messages.createRestriction} />
          </FormButton>
        </div>
      </RestrictionForm>
    </div>
  );
};
