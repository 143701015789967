import { stringifyParams } from '@cooltra/utils';
import { useIntl } from 'react-intl';
import { MdAdd, MdOutlineCalendarToday, MdSearch } from 'react-icons/md';
import { useAuthClaimsQuery } from '@cooltra/auth-api';

import { usePreferredServicePoints } from '~/libs/preferred-service-points';
import { CustomIcon } from '~/common';

import { formatDateQueryParam } from '../../utils';
import { NavigationLink } from '../NavigationLink';
import { NewContractType } from '../NewContractType/NewContractType';

import messages from './messages';

export const Navigation = () => {
  const { formatMessage } = useIntl();
  const { hasPermission } = useAuthClaimsQuery();

  const { preferredServicePoints: savedServicePointIds } =
    usePreferredServicePoints();

  return (
    <div className="w-16 h-full p-1 flex flex-col gap-3 bg-neutral-0 z-10 shadow-md relative pt-3">
      <NavigationLink
        to={`/pos/daily?${stringifyParams({
          date: formatDateQueryParam(new Date()),
          servicePointId: savedServicePointIds,
          status: ['ACTIVE', 'BOOKED', 'NO_SHOW', 'DRAFT'],
        })}`}
        aria-label={formatMessage(messages.dailyContracts)}
      >
        <MdOutlineCalendarToday />
      </NavigationLink>
      <NavigationLink
        to="/pos/contracts"
        aria-label={formatMessage(messages.search)}
      >
        <MdSearch />
      </NavigationLink>
      {hasPermission('write:station_based_subscriptions') ? (
        <NewContractType />
      ) : (
        <NavigationLink
          to="/pos/new-contract"
          aria-label={formatMessage(messages.newDraftContract)}
        >
          <MdAdd />
        </NavigationLink>
      )}
      <NavigationLink
        to="/pos/vehicles"
        aria-label={formatMessage(messages.vehicles)}
      >
        <CustomIcon.Motorcycle />
      </NavigationLink>
    </div>
  );
};
