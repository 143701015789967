import { useNavigate, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { useServicePointQuery } from '@cooltra/station-based-api';
import { getErrorStatus } from '@cooltra/axios';
import { Spinner } from '@cooltra/ui';

import { BackLink, ErrorPage, Four0Four, Four0Three } from '~/common';

import messages from './messages';
import { ServicePointForm } from './ServicePointForm';
import { VehicleTypesSection } from './VehicleTypesSection/VehicleTypesSection';

export const ServicePoint = () => {
  const { servicePointId = '' } = useParams<'servicePointId'>();
  const { hasPermission } = useAuthClaimsQuery();
  const navigate = useNavigate();
  const navigateBack = () => navigate(-1);
  const {
    data: servicePointData,
    isLoading,
    isError,
    error,
  } = useServicePointQuery(servicePointId, {
    enabled: hasPermission('read:prices'),
  });

  if (!hasPermission('read:prices')) {
    return <Four0Three />;
  }

  if (isLoading) {
    return (
      <div className="w-full text-center my-8">
        <Spinner size="lg" />
      </div>
    );
  }

  if (isError && getErrorStatus(error) === 404) {
    return <Four0Four />;
  }

  if (isError) {
    return <ErrorPage />;
  }

  if (!servicePointData) {
    return <Four0Four />;
  }

  const { vehicleTypes, ...servicePoint } = servicePointData;

  return (
    <div className="container min-w-3xl max-w-6xl py-12 pb-48">
      <BackLink onClick={navigateBack}>
        <FormattedMessage {...messages.backToList} />
      </BackLink>
      <ServicePointForm servicePoint={servicePoint} />
      <VehicleTypesSection vehicleTypes={vehicleTypes} saleType="SHORT_TERM" />
      <VehicleTypesSection
        vehicleTypes={vehicleTypes}
        saleType="SUBSCRIPTION"
      />
    </div>
  );
};
