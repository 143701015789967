import { FormProvider, Form, FormHelpers } from '@cooltra/form';
import { Card, Divider } from '@cooltra/ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import {
  usePatchServicePointMutation,
  ServicePoint,
} from '@cooltra/station-based-api';
import { captureMessage } from '@sentry/react';
import { useParams } from 'react-router-dom';

import { FormSaveButton } from '~/common';
import { useNotification } from '~/hooks';

import messages from './messages';
import {
  validateServicePointForm,
  getServicePointFormInitialValues,
  formatServicePointPayload,
  ServicePointFormValues,
} from './service-point-form';
import { ClickAndRideCheck } from './ServicePointFormFields/ClickAndRideCheck';
import { DelegationSelectField } from './ServicePointFormFields/DelegationSelectField';
import { DestinationAccountSelectField } from './ServicePointFormFields/DestinationAccountSelectField';
import { DestinationSelectField } from './ServicePointFormFields/DestinationSelectField';
import { IsActiveToggle } from './ServicePointFormFields/IsActiveToggle';
import { IsVisibleCheck } from './ServicePointFormFields/IsVisibleCheck';
import { MinDaysField } from './ServicePointFormFields/MinDaysField';
import { MunicipalityCodeField } from './ServicePointFormFields/MunicipalityCodeField';
import { PrerollHours } from './ServicePointFormFields/PrerollHours';
import { POSPricingGroupSelect } from './ServicePointFormFields/PricingGroupPOSSelect';
import { WebPricingGroupSelect } from './ServicePointFormFields/PricingGroupWebSelect';
import { SubscriptionsPricingGroupSelect } from './ServicePointFormFields/PricingGroupSubscriptionsSelect';

export type ServicePointFormProps = {
  servicePoint: Omit<ServicePoint, 'vehicleTypes'>;
};

export const ServicePointForm = ({ servicePoint }: ServicePointFormProps) => {
  const { servicePointId = '' } = useParams<'servicePointId'>();
  const { hasPermission } = useAuthClaimsQuery();
  const intl = useIntl();

  const { addErrorNotification, addSuccessNotification } = useNotification();
  const { mutateAsync, isSuccess } =
    usePatchServicePointMutation(servicePointId);

  const onSubmit = async (
    values: ServicePointFormValues,
    { resetForm }: FormHelpers<ServicePointFormValues>
  ) =>
    mutateAsync(formatServicePointPayload(values))
      .then(() => {
        resetForm(values);
        addSuccessNotification(intl.formatMessage(messages.success));
      })
      .catch((e) => {
        captureMessage('ERROR_UPDATING_SERVICE_POINT', {
          extra: {
            error: e,
            values,
          },
        });
        addErrorNotification();
      });

  return (
    <FormProvider
      onSubmit={onSubmit}
      validate={validateServicePointForm(intl)}
      initialValues={getServicePointFormInitialValues(servicePoint)}
      disabled={!hasPermission('write:prices')}
    >
      <Form>
        <div className="flex justify-between pb-8">
          <h2>{servicePoint.name}</h2>
          <IsActiveToggle servicePointId={servicePointId} />
        </div>
        <Card className="mb-5">
          <div className="p-8 grid grid-cols-2 gap-x-11 gap-y-10">
            <h3 className="text-base text-neutral-400 pb-1 col-span-2">
              <FormattedMessage {...messages.general} />
            </h3>
            <DelegationSelectField />
            <DestinationSelectField />
            <DestinationAccountSelectField />
            <MunicipalityCodeField />
            <MinDaysField />
            <PrerollHours />
            <ClickAndRideCheck />
          </div>

          <Divider direction="horizontal" className="mb-8" />
          <div className="p-8 grid grid-cols-2 gap-x-11 gap-y-10">
            <h3 className="text-base text-neutral-400 pb-1 col-span-2">
              <FormattedMessage {...messages.priceLists} />
            </h3>
            <WebPricingGroupSelect />
            <POSPricingGroupSelect />
            <SubscriptionsPricingGroupSelect />
          </div>

          <Divider direction="horizontal" className="mb-8" />
          <div className="p-8 grid grid-cols-2 gap-x-11 gap-y-10">
            <h3 className="text-base text-neutral-400 pb-1 col-span-2">
              <FormattedMessage {...messages.webPreferences} />
            </h3>
            <IsVisibleCheck />
          </div>
        </Card>
        <FormSaveButton
          className="btn-shadow fixed bottom-8 right-28 -mr-2 z-50"
          isSaved={isSuccess}
        />
      </Form>
    </FormProvider>
  );
};
