import { FormInputField } from '@cooltra/form';
import { InputLabel } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import messages from '../messages';

export const MinDaysField = () => {
  return (
    <FormInputField
      name="minDaysForDeliveryPoint"
      id="min-days-for-delivery-point"
      type="number"
      label={
        <InputLabel htmlFor="min-days-for-delivery-point">
          <FormattedMessage {...messages.minDays} />
        </InputLabel>
      }
    />
  );
};
