import { FormattedMessage, useIntl } from 'react-intl';
import {
  DailyContractsListItem,
  ContractStatus,
} from '@cooltra/station-based-api';
import { MdLocationOn, MdCall, MdPerson, MdError } from 'react-icons/md';
import { Badge, Divider } from '@cooltra/ui';
import { classNames, getInsuranceName, getVehicleName } from '@cooltra/utils';

import { getVehicleTypeCategoryName } from '~/utils/vehicle-type';
import {
  AccessoryTag,
  ClickAndRideLogo,
  SubscriptionContractState,
} from '~/common';
import { isCustomDelivery, isCustomReturn } from '~/utils/accessories';
import { getContractPlanMessage } from '~/utils/contract';

import { ContractCardRouterLink } from '../ContractCard';

import messages from './messages';

export type ContractCardProps = {
  contract: DailyContractsListItem;
};

const statusColor = (status: ContractStatus) => {
  switch (status) {
    case 'ACTIVE':
      return 'border-success-500';
    case 'DRAFT':
      return 'border-neutral-500';
    case 'BOOKED':
      return 'border-warning-500';
    case 'CANCELED':
      return 'border-danger-500';
    case 'NO_SHOW':
      return 'border-danger-500';
    case 'CLOSED':
      return 'border-neutral-800';
    default:
      return 'border-neutral-800';
  }
};

export const ContractCard = ({
  contract: {
    contractId,
    status,
    servicePoint,
    contractNumber,
    user,
    serviceType,
    vehicleType,
    accessories,
    insurance,
    hasClickAndRide,
    numberOfDays,
    hasCheckInCompleted,
    isSubscription,
    monthsTerm,
    plan,
    autoRenew,
  },
}: ContractCardProps) => {
  const { formatMessage } = useIntl();
  const regularAccessories = (accessories || []).filter(
    ({ name }) => !isCustomReturn(name) && !isCustomDelivery(name)
  );

  const customAccessories = (accessories || []).filter(
    ({ name }) => isCustomReturn(name) || isCustomDelivery(name)
  );

  const shouldShowCheckInWarning =
    !hasCheckInCompleted && ['DRAFT', 'BOOKED'].includes(status);

  const getUserSection = () => {
    if (!user) {
      return (
        <span className="flex items-center text-neutral-300">
          <MdPerson className="mr-2 text-lg" />
          <FormattedMessage {...messages.missingUserData} />
        </span>
      );
    }

    return (
      <>
        <span className="flex items-center">
          <MdPerson className="mr-2 text-lg" />
          {user.name} {user.surname}
        </span>
        <Divider direction="vertical" className="h-6" />
        <span className="flex items-center">
          <MdCall className="mr-2 text-lg" />
          {user.phone}
        </span>
      </>
    );
  };

  const getContentSection = () => {
    if (!vehicleType) {
      return (
        <div className="flex gap-4 text-neutral-300">
          <span>
            <FormattedMessage {...messages.missingVehicleData} />
          </span>
        </div>
      );
    }

    return (
      <div className="flex gap-4 flex-wrap text-neutral-700">
        <Badge>{getVehicleTypeCategoryName(vehicleType.category)}</Badge>
        <span className="whitespace-nowrap">
          {getVehicleName(vehicleType.name)}
          {vehicleType.assignedVehicle && (
            <>
              <span> - </span>
              <span className="font-semibold text-neutral-800">
                {vehicleType.assignedVehicle.licensePlate}
              </span>
            </>
          )}
        </span>
        {!vehicleType.assignedVehicle && (
          <span className="flex items-center flex-nowrap gap-1 text-neutral-700">
            <MdError className="text-lg text-warning-500" />
            <FormattedMessage {...messages.assignLicensePlate} />
          </span>
        )}
        {insurance && (
          <>
            <Divider direction="vertical" className="h-6" />
            <Badge emphasis="low">{getInsuranceName(insurance)}</Badge>
          </>
        )}
        {accessories.length > 0 && (
          <Divider direction="vertical" className="h-6" />
        )}
        {[...customAccessories, ...regularAccessories].map((accessory) => (
          <AccessoryTag
            key={accessory.id}
            name={accessory.name}
            quantity={accessory.quantity}
          />
        ))}
      </div>
    );
  };

  return (
    <ContractCardRouterLink id={contractId} isSubscription={isSubscription}>
      <div
        className={classNames(
          'border-solid border-x-[6px] py-6 px-7 flex flex-col gap-4 relative',
          statusColor(status),
          serviceType === 'PICK_UP'
            ? 'border-r-neutral-0'
            : 'border-l-neutral-0'
        )}
      >
        <div className="flex gap-4 items-center justify-between font-semibold text-neutral-800">
          <div className="flex items-center gap-4">
            <span className="text-xl font-semibold text-neutral-700 tabular-nums">
              {contractNumber}
            </span>
            <Divider direction="vertical" className="h-6" />
            {getUserSection()}
            <Divider direction="vertical" className="h-6" />
            {shouldShowCheckInWarning && (
              <span className="flex items-center flex-nowrap gap-1 font-normal text-neutral-700">
                <MdError className="text-lg text-warning-500" />
                <FormattedMessage {...messages.missingCheckIn} />
              </span>
            )}
          </div>
          {hasClickAndRide && <ClickAndRideLogo className="h-5" />}
        </div>
        {getContentSection()}
        <div className="flex gap-2 items-center">
          <div className="flex items-center">
            <MdLocationOn className="mr-2text-lg fill-neutral-400" />
            <span className="text-sm text-neutral-600">
              {servicePoint.name}
            </span>
          </div>
          <Divider direction="vertical" className="h-6" />
          {isSubscription ? (
            <>
              <span className="text-neutral-700">
                <FormattedMessage
                  {...messages.months}
                  values={{ totalMonths: monthsTerm }}
                />
              </span>
              {plan && (
                <Badge emphasis="low">
                  {formatMessage(getContractPlanMessage(plan))}
                </Badge>
              )}
              <SubscriptionContractState
                compact={false}
                autoRenew={!!autoRenew}
              />
            </>
          ) : (
            <span className="text-neutral-700">
              <FormattedMessage
                {...messages.days}
                values={{ totalDays: numberOfDays }}
              />
            </span>
          )}
        </div>
      </div>
    </ContractCardRouterLink>
  );
};
