import { defineMessages } from 'react-intl';

export default defineMessages({
  payTicket: {
    defaultMessage: 'Charge',
    id: 'contract_charge',
  },
  legend: {
    defaultMessage: 'Payment method',
  },
  sendPayment: {
    defaultMessage: 'Confirm charge of {amount}',
  },
  confirmPayment: {
    defaultMessage: 'I confirm that the client has paid',
  },
});
