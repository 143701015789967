import { FormattedMessage } from 'react-intl';
import { Contract, ContractPayment } from '@cooltra/station-based-api';
import { RouterTab } from '@cooltra/navigation';

import { PaidTicket } from '~/common';
import { useSubscriptionContractFlags } from '~/hooks';

import { TicketPendingPayment } from '../TicketPendingPayment/TicketPendingPayment';
import { SubscriptionTicket } from '../SubscriptionTicket/SubscriptionTicket';

import messages from './messages';

export type SubscriptionPastChargesProps = {
  contract: Contract;
  payments: ContractPayment[];
};

export const SubscriptionPastCharges = ({
  contract,
  payments,
}: SubscriptionPastChargesProps) => {
  const { isSignedOrPendingSignature } = useSubscriptionContractFlags();

  const paidPayments = payments
    .filter(
      ({ status }) =>
        status === 'PAID' ||
        status === 'REFUNDED' ||
        status === 'PENDING_RECURRENT_PAYMENT' ||
        status === 'FAILED'
    )
    .sort(
      (a, b) =>
        new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
    );
  const awaitingPayments =
    payments.filter(({ status }) => status === 'AWAITING_PAYMENT') || [];

  if (!paidPayments.length && !awaitingPayments.length) {
    return null;
  }

  return (
    <>
      <div className="my-8">
        <RouterTab to=".">
          <FormattedMessage {...messages.pastCharges} />
        </RouterTab>
      </div>
      <div
        data-testid="SUBSCRIPTION_PAST_CHARGES"
        className="flex flex-col gap-4"
      >
        {awaitingPayments.map((awaitingPaymentTicket) => (
          <TicketPendingPayment
            contractId={contract.contractId}
            ticket={awaitingPaymentTicket}
            key={awaitingPaymentTicket.id}
          />
        ))}
        {paidPayments.map((paidPayment) =>
          paidPayment.type === 'SUBSCRIPTION' ? (
            <SubscriptionTicket
              key={paidPayment.id}
              payment={paidPayment}
              contractId={contract.contractId}
            />
          ) : (
            <PaidTicket
              refundDisabled={
                isSignedOrPendingSignature || paidPayment.totalPrice.value === 0
              }
              key={paidPayment.id}
              contractId={contract.contractId}
              contractStatus={contract.status}
              payment={paidPayment}
              isSubscription={contract.isSubscription}
            />
          )
        )}
      </div>
    </>
  );
};
