import { ThreeStateValue, TasksSort } from '@cooltra/api';
import { System } from '@cooltra/utils';

export type TasksFiltersFormValues = {
  system: System[];
  operatorGroupId: string;
  operatorId: string;
  tagId: string[];
  open: ThreeStateValue;
  blocking: boolean;
  sort: TasksSort;
};

export const tasksFiltersInitialValues: TasksFiltersFormValues = {
  system: [],
  operatorGroupId: '',
  operatorId: '',
  tagId: [],
  open: 'true',
  blocking: false,
  sort: 'updatedAt.desc',
};
