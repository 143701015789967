import { Contract } from '@cooltra/station-based-api';
import { format } from 'date-fns';
import { IntlShape } from 'react-intl';

import { dateFormat } from '~/utils/date';
import { createSetError, errorMessages, validateDate } from '~/validation';

export type CancelSubscriptionRenewalFormValues = {
  endDate: string;
  endTime: string;
};

export type CancelSubscriptionRenewalFormFieldName =
  keyof CancelSubscriptionRenewalFormValues;

type CancelSubscriptionRenewalFormErrors = Partial<
  Record<CancelSubscriptionRenewalFormFieldName, string | undefined>
>;

export const getInitialCancelSubscriptionRenewalFormValues = (
  contract: Contract
): CancelSubscriptionRenewalFormValues => {
  const { details } = contract;

  if (!details?.endDate && !details?.endTime) {
    return {
      endDate: '',
      endTime: '',
    };
  }

  return {
    endDate: format(details!.endDate!, dateFormat),
    endTime: details!.endTime!,
  };
};

export const validateCancelSubscriptionRenewalForm =
  (intl: IntlShape) =>
  (
    values: CancelSubscriptionRenewalFormValues
  ): CancelSubscriptionRenewalFormErrors => {
    const errors: CancelSubscriptionRenewalFormErrors = {};
    const setError = createSetError(errors);

    const requiredFields: CancelSubscriptionRenewalFormFieldName[] = [
      'endTime',
    ];
    requiredFields.forEach((fieldName) => {
      setError(fieldName, validateRequired(intl, values[fieldName]));
    });
    setError('endDate', validateDate(intl, values.endDate));

    return errors;
  };

export const validateRequired = (intl: IntlShape, value: any) =>
  value === '' || value === undefined
    ? intl.formatMessage(errorMessages.required)
    : undefined;
