import { useEffect, useState } from 'react';
import { MapLayerMouseEvent, ViewStateChangeEvent } from 'react-map-gl';
import { System } from '@cooltra/utils';
import {
  systemGeolocations,
  useBonusZonesQuery,
  useGeofenceRestrictionsQuery,
  VehicleType,
} from '@cooltra/api';

import { BaseMap, Geofence } from '~/common';

import { RestrictionFeature } from '../RestrictionFeature/RestrictionFeature';
import { BonusZoneFeature } from '../BonusZoneFeature/BonusZoneFeature';
import { useFocusedZone } from '../FocusedZone';

import { MapLegend } from './MapLegend/MapLegend';

export type ZonesMapProps = {
  system: System;
  vehicleType: VehicleType;
};

export const ZonesMap = ({ system, vehicleType }: ZonesMapProps) => {
  const {
    setBonusZoneIdFocused,
    bonusZoneIdFocused,
    setRestrictionIdFocused,
    restrictionIdFocused,
  } = useFocusedZone();

  const { data } = useBonusZonesQuery(system, { vehicleType });
  const { data: restrictions = [] } = useGeofenceRestrictionsQuery(system, {
    vehicleType,
  });

  const [viewState, updateViewState] = useState({
    zoom: 12,
    ...systemGeolocations[system],
  });

  const onMove = ({
    viewState: { latitude, longitude, zoom },
  }: ViewStateChangeEvent) => updateViewState({ latitude, longitude, zoom });

  useEffect(() => {
    if (system) {
      updateViewState({
        zoom: 12,
        ...systemGeolocations[system],
      });
    }
  }, [system]);

  const bonusZones = data?.bonusZones || [];
  const handleOnMouseEnter = (e: MapLayerMouseEvent) => {
    const feature = e.features ? e.features[0] : undefined;
    const properties = feature?.properties;

    if (properties?.type === 'bonus') {
      setBonusZoneIdFocused(properties.id);
    }
    if (properties?.type === 'restriction') {
      setRestrictionIdFocused(properties.id);
    }
  };

  const handleOnMouseLeave = (e: MapLayerMouseEvent) => {
    const feature = e.features ? e.features[0] : undefined;
    const properties = feature?.properties;

    if (properties?.type === 'bonus') {
      setBonusZoneIdFocused(undefined);
    }
    if (properties?.type === 'restriction') {
      setRestrictionIdFocused(undefined);
    }
  };

  const bonusZoneLayerIds = bonusZones.map(
    ({ bonusZoneId }) => `bonus-zone-layer-${bonusZoneId}`
  );
  const restrictionLayerIds = restrictions.map(
    ({ geofenceRestrictionId }) => `bonus-zone-layer-${geofenceRestrictionId}`
  );

  return (
    <BaseMap
      interactiveLayerIds={[...bonusZoneLayerIds, ...restrictionLayerIds]}
      style={{ width: '100%', height: '100%' }}
      onMove={onMove}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
      {...viewState}
    >
      <Geofence variant="original" system={system} vehicleType={vehicleType} />
      {restrictions.map(
        ({ polygon, geofenceRestrictionId, enabled, title, marker }, index) => (
          <RestrictionFeature
            isFocused={restrictionIdFocused === geofenceRestrictionId}
            layerId={restrictionLayerIds[index]}
            key={geofenceRestrictionId}
            title={title}
            marker={marker}
            enabled={enabled}
            geofenceRestrictionId={geofenceRestrictionId}
            coordinates={polygon.coordinates}
          />
        )
      )}
      {bonusZones.map(
        ({ polygon, bonusZoneId, enabled, title, marker }, index) => (
          <BonusZoneFeature
            isFocused={bonusZoneIdFocused === bonusZoneId}
            layerId={bonusZoneLayerIds[index]}
            key={bonusZoneId}
            title={title}
            marker={marker}
            enabled={enabled}
            bonusZoneId={bonusZoneId}
            coordinates={polygon.coordinates ? polygon.coordinates[0] : []}
          />
        )
      )}
      <MapLegend />
    </BaseMap>
  );
};
