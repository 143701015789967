import { ReservationState } from '@cooltra/api';
import { System } from '@cooltra/utils';

import { threeDaysAgo } from '~/utils/date';

export type ReservationsFiltersFormValues = {
  system: System[];
  state: ReservationState[];
  minDate: string;
  maxDate: string;
};

export const initialReservationsFiltersFormValues: ReservationsFiltersFormValues =
  {
    system: [],
    state: ['ACTIVE', 'CHECKING_IN'],
    minDate: threeDaysAgo,
    maxDate: '',
  };
