import { useState } from 'react';
import { Button, InputLabel, Modal, SelectField } from '@cooltra/ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { System } from '@cooltra/utils';

import { useSystemOptions } from '../System';

import messages from './messages';

export type ChangeVehiclesHomeSystemModalProps = {
  isOpen: boolean;
  title: string;
  onClose: () => void;
  onSave: (newHomeSystem: System) => void;
  loading?: boolean;
};

export const ChangeVehiclesHomeSystemModal = ({
  title,
  onSave,
  onClose,
  isOpen,
  loading,
}: ChangeVehiclesHomeSystemModalProps) => {
  const { formatMessage } = useIntl();
  const systemOptions = useSystemOptions();

  const [selectedHomeSystem, setSelectedHomeSystem] = useState<
    System | undefined
  >(undefined);

  const handleOnChangeHomeSystem = (value: string) => {
    setSelectedHomeSystem(value as System);
  };

  const handleOnSave = () => {
    if (selectedHomeSystem) {
      onSave(selectedHomeSystem);
      setSelectedHomeSystem(undefined);
    }
  };

  return (
    <Modal
      className="w-96"
      isOpen={isOpen}
      onRequestClose={onClose}
      header={
        <h6 className="text-neutral-400 text-lg px-4 mt-5 font-semibold">
          {title}
        </h6>
      }
    >
      <div
        data-testid="CHANGE_VEHICLE_HOME_SYSTEM_MODAL"
        className="text-center pb-8 px-8"
      >
        <SelectField
          id="select-home-system-field"
          className="mt-8 mb-12 text-left"
          placeholder={formatMessage(messages.selectHomeSystemPlaceholder)}
          noOptionsMessage={() => (
            <FormattedMessage {...messages.noHomeSystem} />
          )}
          options={systemOptions
            .map((system) => ({
              label: system.label,
              value: system.value,
            }))
            .sort((a, b) => a.label.localeCompare(b.label))}
          onChange={handleOnChangeHomeSystem}
          label={
            <InputLabel htmlFor="select-home-system-field">
              <FormattedMessage {...messages.selectNewHomeSystem} />
            </InputLabel>
          }
        />
        <div className="flex justify-center gap-4">
          <Button className="flex-1" onClick={onClose}>
            <FormattedMessage {...messages.cancel} />
          </Button>
          <Button
            data-testid="BUTTON_TO_MOVE_HS_VEHICLE"
            className="flex-1"
            emphasis="high"
            onClick={handleOnSave}
            disabled={!selectedHomeSystem}
            loading={loading}
          >
            <FormattedMessage {...messages.save} />
          </Button>
        </div>
      </div>
    </Modal>
  );
};
