import { FormattedMessage } from 'react-intl';
import { Task, useTaskTemplatesQuery } from '@cooltra/api';
import { System } from '@cooltra/utils';
import { useFormContext, FormAutocompleteField } from '@cooltra/form';

import messages from './messages';

type TaskFormValues = Pick<
  Task,
  | 'title'
  | 'operatorGroupId'
  | 'operatorId'
  | 'tagIds'
  | 'description'
  | 'blocking'
>;

export type TitleProps = {
  system?: System;
  problemReportDescription?: string;
  ignored?: string[];
  disabled?: boolean;
};

export const Title = ({
  system,
  problemReportDescription,
  ignored = [],
  disabled = false,
}: TitleProps) => {
  const { setValues, initialValues, isFormDisabled } =
    useFormContext<TaskFormValues>();
  const { isLoading, data } = useTaskTemplatesQuery(
    {
      system,
      type: 'task',
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const getDescriptionFrom = (
    selectedTaskTemplateDescription: string | undefined,
    problemReportDescription: string | undefined
  ): string => {
    if (selectedTaskTemplateDescription && problemReportDescription) {
      return `
          ${selectedTaskTemplateDescription}\n${problemReportDescription}
      `.trim();
    }

    if (problemReportDescription?.trim()) {
      return problemReportDescription;
    }

    if (selectedTaskTemplateDescription?.trim()) {
      return selectedTaskTemplateDescription;
    }

    return '';
  };

  const handleSelectedItemChange = ({
    selectedItem,
  }: {
    selectedItem?: string | null | undefined;
  }) => {
    if (!selectedItem) {
      return;
    }
    const selectedTaskTemplate = data?.find(
      ({ title }) => title === selectedItem
    );
    if (selectedTaskTemplate) {
      setValues({
        ...initialValues,
        ...selectedTaskTemplate,
        description: getDescriptionFrom(
          selectedTaskTemplate.description,
          problemReportDescription
        ),
      });
    }
  };

  return (
    <FormAutocompleteField
      data-testid="TASK_TITLE_FIELD"
      disabled={isFormDisabled || isLoading || disabled}
      onSelectedItemChange={handleSelectedItemChange}
      items={
        data
          ?.filter(({ title }) => !ignored.includes(title))
          .map(({ title }) => title) || []
      }
      name="title"
      label={<FormattedMessage {...messages.title} />}
    />
  );
};
