import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '@cooltra/ui';
import { Location, useTransferVehiclesMutation } from '@cooltra/api';
import { System } from '@cooltra/utils';
import { useToggle } from '@cooltra/hooks';

import { ChangeVehiclesLocationModal } from '~/common';
import { useNotification } from '~/hooks';

import messages from './messages';

export type TransferProps = {
  systems: System[];
  vehicleIds: string[];
  ignoreTakenVehicles?: boolean;
  refetchVehicles: () => void;
  disabled: boolean;
};

export const Transfer = ({
  systems,
  vehicleIds,
  refetchVehicles,
  disabled,
  ignoreTakenVehicles = true,
}: TransferProps) => {
  const { formatMessage } = useIntl();
  const [isOpen, { toggleOff, toggleOn }] = useToggle();

  const { addErrorNotification, addSuccessNotification } = useNotification();
  const { isPending, mutateAsync } = useTransferVehiclesMutation();

  const handleOnSave = (newLocation: Location) =>
    mutateAsync({
      vehicleIds,
      locationId: newLocation.locationId,
      ignoreTakenVehicles,
    })
      .then(() => {
        addSuccessNotification(
          formatMessage(messages.success, { location: newLocation.name })
        );
        refetchVehicles();
        toggleOff();
      })
      .catch(() => addErrorNotification());

  return (
    <>
      <ChangeVehiclesLocationModal
        title={formatMessage(messages.changeLocation)}
        onSave={handleOnSave}
        onClose={toggleOff}
        systems={systems}
        isOpen={isOpen}
        loading={isPending}
      />
      <Button emphasis="high" size="sm" onClick={toggleOn} disabled={disabled}>
        <FormattedMessage {...messages.changeLocation} />
      </Button>
    </>
  );
};
