import {
  PatchServicePointPayload,
  ServicePoint,
} from '@cooltra/station-based-api';
import { IntlShape } from 'react-intl';

import { errorMessages, createSetError } from '~/validation';

import messages from './messages';

export type ServicePointFormValues = {
  delegation?: string;
  destination?: string;
  destinationAccount?: string;
  municipalityCode?: string | null;
  minDaysForDeliveryPoint?: number;
  shopPrerollHours?: number;
  deliveryPointPrerollHours?: number;
  hasClickAndRide: boolean;
  pricelistWeb?: string;
  pricelistPOS?: string;
  pricelistSubscriptions?: string;
  allowsOnlineSales: boolean;
};

export type ServicePointFormFieldName = keyof ServicePointFormValues;

export type ServicePointFormErrors = Partial<
  Record<ServicePointFormFieldName, string | undefined>
>;

export const validateRequired = (intl: IntlShape, value: any) =>
  value === '' || value === undefined
    ? intl.formatMessage(errorMessages.required)
    : undefined;

export const validateServicePointForm =
  (intl: IntlShape) =>
  (values: ServicePointFormValues): ServicePointFormErrors => {
    const errors: ServicePointFormErrors = {};
    const setError = createSetError(errors);

    if (values.minDaysForDeliveryPoint && values.minDaysForDeliveryPoint < 0) {
      setError(
        'minDaysForDeliveryPoint',
        intl.formatMessage(messages.shouldBePositive)
      );
    }

    if (values.shopPrerollHours && values.shopPrerollHours < 0) {
      setError(
        'shopPrerollHours',
        intl.formatMessage(messages.shouldBePositive)
      );
    }

    if (
      values.deliveryPointPrerollHours &&
      values.deliveryPointPrerollHours < 0
    ) {
      setError(
        'deliveryPointPrerollHours',
        intl.formatMessage(messages.shouldBePositive)
      );
    }

    if (values.municipalityCode) {
      if (values.municipalityCode.length !== 5) {
        setError(
          'municipalityCode',
          intl.formatMessage(messages.invalidLength, { length: '5' })
        );
      }
      if (Number(values.municipalityCode) < 0) {
        setError(
          'municipalityCode',
          intl.formatMessage(messages.shouldBePositive)
        );
      }
      if (isNaN(Number(values.municipalityCode))) {
        setError(
          'municipalityCode',
          intl.formatMessage(errorMessages.invalidCharacters)
        );
      }
    }

    return errors;
  };

export const ServicePointFormInitialValues = {
  isActive: false,
  delegation: '',
  destination: '',
  destinationAccount: '',
  municipalityCode: '',
  minDaysForDeliveryPoint: '',
  shopPrerollHours: '',
  deliveryPointPrerollHours: '',
  hasClickAndRide: false,
  pricelistWeb: '',
  pricelistPOS: '',
  pricelistSubscriptions: '',
  allowsOnlineSales: false,
};

export const getServicePointFormInitialValues = ({
  destination,
  isActive,
  delegation,
  destinationAccount,
  municipalityCode,
  minDaysForDeliveryPoint,
  shopPrerollHours,
  deliveryPointPrerollHours,
  hasClickAndRide,
  pricelistWeb,
  pricelistPOS,
  pricelistSubscriptions,
  allowsOnlineSales,
}: Partial<ServicePoint>) =>
  ({
    ...ServicePointFormInitialValues,
    destination,
    isActive,
    delegation,
    destinationAccount,
    municipalityCode: municipalityCode?.trim() || '',
    minDaysForDeliveryPoint,
    shopPrerollHours: shopPrerollHours || '',
    deliveryPointPrerollHours: deliveryPointPrerollHours || '',
    hasClickAndRide,
    pricelistWeb,
    pricelistPOS,
    pricelistSubscriptions,
    allowsOnlineSales,
  }) as ServicePoint;

export const formatServicePointPayload = ({
  delegation,
  destination,
  destinationAccount,
  municipalityCode,
  minDaysForDeliveryPoint,
  shopPrerollHours,
  deliveryPointPrerollHours,
  hasClickAndRide,
  pricelistWeb,
  pricelistPOS,
  pricelistSubscriptions,
  allowsOnlineSales,
}: ServicePointFormValues): PatchServicePointPayload => ({
  hasClickAndRide,
  allowsOnlineSales,
  delegation: delegation || undefined,
  destination: destination || undefined,
  destinationAccount: destinationAccount || undefined,
  municipalityCode: municipalityCode || undefined,
  minDaysForDeliveryPoint: minDaysForDeliveryPoint
    ? Number(minDaysForDeliveryPoint)
    : undefined,
  shopPrerollHours: shopPrerollHours ? Number(shopPrerollHours) : undefined,
  deliveryPointPrerollHours: deliveryPointPrerollHours
    ? Number(deliveryPointPrerollHours)
    : undefined,
  pricelistPOS: pricelistPOS || undefined,
  pricelistWeb: pricelistWeb || undefined,
  pricelistSubscriptions: pricelistSubscriptions || undefined,
});
