import { FormattedMessage } from 'react-intl';

import { RouterBackLink } from '~/common';

import messages from './messages';

export const DepositsPriceListDetailsBackLink = () => (
  <RouterBackLink to={`/station-based/price-lists/deposits`}>
    <FormattedMessage {...messages.title} />
  </RouterBackLink>
);
