import {
  ContractServiceType,
  ContractStatus,
  SaleType,
} from '@cooltra/station-based-api';
import { getParams } from '@cooltra/utils';
import { useLocation } from 'react-router-dom';

import { parseDateQueryParam } from './utils';

export const useFilters = () => {
  const { search } = useLocation();
  const date = getParams(search).string('date');
  const serviceType = getParams(search).string(
    'serviceType'
  ) as ContractServiceType | null;
  const servicePointId = getParams(search).array('servicePointId');
  const status = getParams(search).array('status') as ContractStatus[];
  const saleType = getParams(search).string('saleType') as SaleType | null;

  return {
    date: parseDateQueryParam(date!),
    serviceType,
    servicePointId,
    status,
    saleType,
  };
};
