import { FormSelectFieldProps, FormSelectField } from '@cooltra/form';
import { InputLabel } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { DestinationAccountOptions } from '@cooltra/station-based-api';

import messages from './messages';

export type DestinationAccountSelectFieldProps = Omit<
  FormSelectFieldProps,
  'name' | 'id' | 'label' | 'isClearable' | 'options'
>;

export const DestinationAccountSelectField = (
  props: DestinationAccountSelectFieldProps
) => {
  return (
    <FormSelectField
      id="destination-account-field"
      name="destinationAccount"
      options={DestinationAccountOptions}
      isClearable
      label={
        <InputLabel htmlFor="destination-account-field">
          <FormattedMessage {...messages.destinationAccount} />
        </InputLabel>
      }
      {...props}
    />
  );
};
