import { RouterCardLink } from '@cooltra/navigation';
import {
  InsuranceDepositPrices,
  isBike,
  VehicleTypeCategory,
} from '@cooltra/station-based-api';
import { capitalize } from '@cooltra/utils';

import { DepositsRowLayout } from './DepositsRowLayout';

export const DepositsCard = ({
  insuranceId,
  insuranceName,
  deposits,
}: InsuranceDepositPrices) => {
  const eBikePrices = deposits.filter((value) => {
    return isBike(value.vehicleTypeCategory as VehicleTypeCategory);
  });
  const eBikePrice =
    eBikePrices.length > 0 ? eBikePrices[0].amount.value + ' €' : '';

  const standardPrices = deposits.filter((value) => {
    return value.vehicleTypeCategory === 'STANDARD';
  });
  const standardPrice =
    standardPrices.length > 0 ? standardPrices[0].amount.value + ' €' : '';

  const plusPrices = deposits.filter((value) => {
    return value.vehicleTypeCategory === 'PLUS';
  });
  const plusPrice =
    plusPrices.length > 0 ? plusPrices[0].amount.value + ' €' : '';

  const premiumPrices = deposits.filter((value) => {
    return value.vehicleTypeCategory === 'PREMIUM';
  });
  const premiumPrice =
    premiumPrices.length > 0 ? premiumPrices[0].amount.value + ' €' : '';

  const superPremiumPrices = deposits.filter((value) => {
    return value.vehicleTypeCategory === 'SUPER_PREMIUM';
  });
  const superPremiumPrice =
    superPremiumPrices.length > 0
      ? superPremiumPrices[0].amount.value + ' €'
      : '';

  return (
    <RouterCardLink
      to={`/station-based/price-lists/deposits/${insuranceId}`}
      data-testid="DEPOSITS_PRICES"
      isMultiAction
    >
      <DepositsRowLayout
        className="min-h-20"
        coverage={
          <span className="text-center font-semibold">
            {capitalize(insuranceName.toLowerCase())}
          </span>
        }
        eBike={<span className="text-center font-semibold">{eBikePrice}</span>}
        standard={
          <span className="text-center font-semibold">{standardPrice}</span>
        }
        plus={<span className="text-center font-semibold">{plusPrice}</span>}
        premium={
          <span className="text-center font-semibold">{premiumPrice}</span>
        }
        superpremium={
          <span className="text-center font-semibold">{superPremiumPrice}</span>
        }
      />
    </RouterCardLink>
  );
};
