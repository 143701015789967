import { Button, Modal } from '@cooltra/ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { Contract } from '@cooltra/station-based-api';
import { MdSyncDisabled } from 'react-icons/md';
import { useToggle } from '@cooltra/hooks';
import { useFormContext } from '@cooltra/form';

import { useNotification } from '~/hooks';
import { isValidDate } from '~/validation';

import { CancelSubscriptionRenewalFormValues } from '../SubscriptionContractOverview/subscription-contract-overview-form';

import messages from './messages';

export type CancelRenewalButtonProps = {
  contract: Contract;
};

export const CancelRenewalButton = ({
  contract: { autoRenew, details },
}: CancelRenewalButtonProps) => {
  const [isOpen, { toggleOn, toggleOff }] = useToggle();
  const intl = useIntl();
  const { addSuccessNotification, addErrorNotification } = useNotification();
  const { handleSubmit, values } =
    useFormContext<CancelSubscriptionRenewalFormValues>();
  const handleConfirm = () =>
    handleSubmit()
      .then(toggleOff)
      .then(() => {
        if (autoRenew)
          addSuccessNotification(
            intl.formatMessage(messages.successCancelRenewal)
          );
        else
          addSuccessNotification(
            intl.formatMessage(messages.successUpdateDropOffAppointment)
          );
      })
      .catch(addErrorNotification);

  const dateHasChanged =
    !!values &&
    !!values.endDate &&
    !!values.endTime &&
    isValidDate(values.endDate) &&
    values.endDate !== details?.endDate &&
    values.endTime !== details?.endTime;

  return (
    <>
      <Modal isOpen={isOpen} onRequestClose={toggleOff}>
        <div
          className="text-center pb-8 px-8"
          data-testid="CANCEL_RENEWAL_MODAL"
        >
          <h6 className="text-neutral-700 text-xl font-semibold mb-6">
            <FormattedMessage {...messages.confirmCancelRenewalTitle} />
          </h6>
          <p className="text-neutral-700 text-lg mb-14">
            <FormattedMessage {...messages.confirmCancelRenewalContent} />
          </p>
          <div className="flex justify-center gap-4">
            <Button size="md" onClick={toggleOff}>
              <FormattedMessage {...messages.confirmCancelRenewalClose} />
            </Button>
            <Button size="md" emphasis="high" onClick={handleConfirm}>
              <FormattedMessage {...messages.confirmCancelRenewalConfirm} />
            </Button>
          </div>
        </div>
      </Modal>
      {autoRenew ? (
        <Button
          variant="danger"
          emphasis="medium"
          size="md"
          leadingIcon={<MdSyncDisabled className="size-4" />}
          onClick={toggleOn}
          disabled={!dateHasChanged}
        >
          <FormattedMessage {...messages.cancelRenewal} />
        </Button>
      ) : (
        <Button
          emphasis="medium"
          size="md"
          leadingIcon={<MdSyncDisabled className="size-4" />}
          onClick={handleConfirm}
          disabled={!dateHasChanged}
        >
          <FormattedMessage {...messages.updateAndSendEmail} />
        </Button>
      )}
    </>
  );
};
