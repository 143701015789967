import { defineMessages } from 'react-intl';

export default defineMessages({
  expandSummary: {
    defaultMessage: 'Expand summary',
  },
  subscription: {
    defaultMessage: 'Subscription - {yearMonth}',
  },
  pendingCharge: {
    defaultMessage: 'Pending charge',
  },
  pdfDoesNotExist: {
    defaultMessage: 'The PDF is not available for this invoice',
  },
  somethingWentWrongOnDownloadingPdf: {
    defaultMessage: 'Something went wrong trying to download the PDF',
  },
  invoicePdf: {
    defaultMessage: 'Invoice PDF',
  },
  unpaid: {
    defaultMessage: 'Unpaid ({errorMessage})',
  },
  retry: {
    defaultMessage: 'Retry',
  },
});
