import { Form, FormHelpers, FormProvider } from '@cooltra/form';
import { FormattedMessage, useIntl } from 'react-intl';
import { Card } from '@cooltra/ui';
import {
  Contract,
  isBike,
  usePatchContractDriverMutation,
  useServicePointsQuery,
} from '@cooltra/station-based-api';
import { captureMessage } from '@sentry/react';

import { useNotification } from '~/hooks';

import { UnsavedChangesPrompt } from '../../../UnsavedChangesPrompt/UnsavedChangesPrompt';
import { FormSaveButton } from '../../../SaveButton';
import { FormQRCode } from '../../../QRCode';
import { DriverLicenseForm, IdCardFields, UserFields } from '../../../User';
import { DriverFields } from '../DriverFields';
import { DriverPhoto } from '../DriverPhoto/DriverPhoto';
import {
  DriverDetailsFormValues,
  formatDriverDetailsPayload,
  getDriverFormInitialValues,
  validateDriverDetailsForm,
} from '../contract-driver-details-form';

import messages from './messages';

export type ContractDriverFormProps = {
  contract: Contract;
  disabled?: boolean;
  isTaxIdRequired?: boolean;
};

export const ContractDriverForm = ({
  contract,
  disabled = false,
  isTaxIdRequired = false,
}: ContractDriverFormProps) => {
  const intl = useIntl();

  const { data: servicePoints = [] } = useServicePointsQuery(
    {},
    {
      enabled: false,
    }
  );

  const { status, contractId, vehicle, details } = contract;

  const mutation = usePatchContractDriverMutation(contractId);

  const { addErrorNotification } = useNotification();

  const handleSubmit = (
    values: DriverDetailsFormValues,
    { resetForm }: FormHelpers<DriverDetailsFormValues>
  ) =>
    mutation
      .mutateAsync(formatDriverDetailsPayload(values))
      .then(() => resetForm(values))
      .catch((e) => {
        captureMessage('ERROR_UPDATING_DRIVER_DETAILS', {
          extra: {
            error: e,
            contract,
          },
        });
        addErrorNotification();
      });

  const enabled = status === 'DRAFT' || status === 'BOOKED';

  if (!details) {
    return null;
  }

  const servicePointCountry = servicePoints.find(
    (servicePoint) => servicePoint.id === details.servicePointId
  )?.country;

  return (
    <FormProvider
      initialValues={getDriverFormInitialValues(contract)}
      onSubmit={handleSubmit}
      validate={validateDriverDetailsForm(intl, servicePointCountry)}
      disabled={!enabled || disabled}
    >
      <h2 className="text-xl text-neutral-700 mb-4">
        <FormattedMessage {...messages.personalInformation} />
      </h2>
      <Form>
        <Card className="pt-8 pb-14 px-10 mb-16 grid grid-cols-2 gap-x-11 gap-y-10">
          <UserFields.FirstName />
          <UserFields.LastName />
          <UserFields.MobilePhone />
          <UserFields.Email />
          <DriverFields.Language skip={['ca']} />
          <div className="col-span-2">
            <DriverFields.Street />
          </div>
          <div className="grid grid-cols-3 gap-x-5 gap-y-10 col-span-2">
            <DriverFields.Zip />
            <DriverFields.City />
            <DriverFields.Country />
          </div>
        </Card>
        <h2 className="text-xl text-neutral-700 mb-6">
          <FormattedMessage {...messages.identityDocument} />
        </h2>
        <Card className="px-10 pt-10 pb-8 flex gap-14 mb-16">
          <div className="flex flex-col gap-6 w-72 shrink-0">
            <DriverPhoto
              testId="ID_CARD_FRONT_PHOTO"
              name="idCardFront"
              side="front"
            />
            <DriverPhoto name="idCardBack" side="back" />
          </div>
          <div>
            <div className="flex w-64 flex-col gap-y-10">
              <DriverFields.DateOfBirth />
              <DriverFields.IdCardCountry />
              <IdCardFields.IdNumber />
              <IdCardFields.IdCardExpirationDate />
              <IdCardFields.TaxIdNumber optional={!isTaxIdRequired} />
            </div>
          </div>
        </Card>
        {!isBike(vehicle?.category) && (
          <>
            <h2 className="text-xl text-neutral-700 mb-6">
              <FormattedMessage {...messages.driverLicense} />
            </h2>
            <Card
              data-testid="DRIVER_LICENSE_FORM_FIELDS"
              className="px-10 pt-10 pb-8 flex gap-14 mb-16"
            >
              <div className="flex flex-col gap-6 w-72 shrink-0">
                <DriverPhoto name="driverLicenseFront" side="front" />
                <DriverPhoto name="driverLicenseBack" side="back" />
              </div>
              <div>
                <div className="flex flex-col gap-y-10">
                  <div className="w-64 flex flex-col gap-y-10">
                    <DriverFields.DriverLicenseNumber />
                    <DriverLicenseForm.ExpirationDate optional={true} />
                    <DriverLicenseForm.DriverLicenseCountry />
                  </div>
                </div>
              </div>
            </Card>
          </>
        )}
        <h2 className="text-xl text-neutral-700 mb-6">
          <FormattedMessage {...messages.selfie} />
        </h2>
        <Card className="px-10 pt-10 pb-8 flex gap-14 mb-16">
          <div className="flex flex-col gap-6 w-72 shrink-0">
            <DriverPhoto name="selfie" isDocument={false} />
          </div>
        </Card>
        {enabled && (
          <FormSaveButton
            className="btn-shadow fixed bottom-8 right-28 -mr-2 z-10"
            isSaved={mutation.isSuccess}
          />
        )}
      </Form>
      <FormQRCode />
      <UnsavedChangesPrompt />
    </FormProvider>
  );
};
