import { defineMessages } from 'react-intl';

export default defineMessages({
  delegation: {
    defaultMessage: 'Delegation',
  },
  destination: {
    defaultMessage: 'Destination',
  },
  destinationAccount: {
    defaultMessage: 'Destination account',
  },
  clickAndRideCheck: {
    defaultMessage: 'Available on Click&Ride',
  },
  active: {
    defaultMessage: 'Active',
  },
  WebPricingGroup: {
    defaultMessage: 'Pricelist web',
  },
  POSPricingGroup: {
    defaultMessage: 'Pricelist POS',
  },
  SubscriptionsPricingGroup: {
    defaultMessage: 'Pricelist subscriptions',
  },
  allowsOnlineSales: {
    defaultMessage: 'Visible on the web',
  },
  prerollShopHours: {
    defaultMessage: 'Preroll shop hours',
  },
  prerollDPHours: {
    defaultMessage: 'Preroll DP hours',
  },
  statusChange: {
    defaultMessage: 'Service point status changed',
  },
});
