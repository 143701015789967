import { useContext } from 'react';
import { ExtraPriceItem } from '@cooltra/station-based-api';
import { capitalize } from '@cooltra/utils';
import { RouterCardLink } from '@cooltra/navigation';

import { PriceColumn } from '~/common';

import { PriceListTypeContext } from '../../PriceListTypeContext';
import { ExtrasRowLayout } from '../ExtrasRowLayout';

export type ExtraProps = {
  extra: ExtraPriceItem;
  extraType: ExtraType;
};

export type ExtraType = 'ACCESSORY' | 'COVERAGE';

export const ExtraCard = ({
  extra: { id, name, price, algorithmPrice, priceList },
  extraType,
}: ExtraProps) => {
  const { type } = useContext(PriceListTypeContext);
  const route =
    extraType === 'COVERAGE'
      ? `/station-based/price-lists/${type.toLowerCase()}/coverages/${id}`
      : `/station-based/price-lists/${type.toLowerCase()}/accessories/${id}`;

  return (
    <RouterCardLink to={route} data-testid={'EXTRA_PRICES-' + id} isMultiAction>
      <ExtrasRowLayout
        className="min-h-16"
        name={capitalize(name.split('_').join(' ').toLowerCase())}
        price={
          price && algorithmPrice ? (
            <PriceColumn
              price={price}
              algorithmPrice={algorithmPrice}
              priceList={priceList}
            />
          ) : (
            <span />
          )
        }
      />
    </RouterCardLink>
  );
};
